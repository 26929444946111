import { KeyMessage } from "../models/messages/key-message.model";
import { capitalize } from "../services/util/string-util";
import { EventHandler } from "./common";

const capturedKeys = ['enter', 'tab', 'escape', 'arrowup', 'arrowdown', 'arrowleft', 'arrowright', 'pagedown', 'pageup', 'end', 'home', 
    'backspace', 'delete', 'insert', 'f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f8', 'f9', 'f10', 'f11', 'f12', 'f13', 'f14', 'f15'];
const capturedCodes = ['space'];
const capturedClipboardEvents = ['copy', 'cut', 'paste'];

export const initKeyEvents = (handler:EventHandler) => {
    const keyHandler = (evt:any) => {
        const eventMsg = new KeyMessage();
        eventMsg.timeString = new Date().toISOString();
        if (capturedKeys.indexOf(evt.key?.toLowerCase()) > -1) {
            eventMsg.key = evt.key;
        } else if (capturedCodes.indexOf(evt.code?.toLowerCase()) > -1) { // handle space key
            eventMsg.key = evt.code;
        } else if (capturedClipboardEvents.indexOf(evt.type?.toLowerCase()) > -1) {
            eventMsg.key = capitalize(evt.type);
        } else {
            return;
        }

        handler(eventMsg);
    };

    document.addEventListener('keydown', keyHandler, true);
    document.addEventListener('cut', keyHandler, true);
    document.addEventListener('copy', keyHandler, true);
    document.addEventListener('paste', keyHandler, true);
}
