import { InputMessage } from "../models/messages/input-message.model";
import { debounce, EventHandler, getElementClass } from "./common";

const inputTypesToCaptureTextInputFrom = ['email', 'number', 'password', 'search', 'tel', 'text', 'url'];
const attachedInputs:Array<any> = [];

export const initInputEvents = (handler:EventHandler) => {
    const inputHandler = (evt:any) => {
        const element = evt.target as HTMLElement;
        let tagName = element.nodeName;
        if (tagName.toUpperCase() === 'INPUT' && element.attributes) {
            const inputType = element.attributes.getNamedItem('type');
            if (inputType) {
                tagName = `INPUT>${inputType.value}`;
            }
        }
    
        const eventMsg = new InputMessage();
        eventMsg.timeString = new Date().toISOString();
        eventMsg.tag = tagName;
        eventMsg.elementClass = getElementClass(element);
        eventMsg.elementId = element.id;
    
        handler(eventMsg);
    };

    const debouncedInputHandler = debounce(inputHandler, 2000);

    const attachInputEventListener = (inputField:any) => {
        if(attachedInputs.indexOf(inputField) < 0) {
            inputField.addEventListener('input', debouncedInputHandler);
        }
    }

    let selectorField = '[contenteditable],textarea,input:not([type])';
    for (const inputType of inputTypesToCaptureTextInputFrom) {
        selectorField += `,input[type="${inputType}"]`;
    }
    const inputs = document.querySelectorAll(selectorField);
    Array.from(inputs).forEach(input => attachInputEventListener(input));
    attachDOMObserver(attachInputEventListener);
}

const attachDOMObserver = (attachInputEventListener:((inputField:any)=>void)) => {
    // attach observer to check every newly added element if we need to attach the input event listener
    const observer = new MutationObserver((mutations:Array<MutationRecord>) => {
        for(const mutation of mutations) {
            mutation.addedNodes.forEach(addedNode => {
                const addedElement = addedNode as any;
                if ((addedElement.attributes && addedElement.attributes['contenteditable'])
                    || (addedElement.nodeName === 'INPUT' && inputTypesToCaptureTextInputFrom.includes(addedElement.type))
                    || (addedElement.nodeName === 'INPUT' && !addedElement.type)
                    || (addedElement.nodeName === 'TEXTAREA')) {
                    attachInputEventListener(addedElement);
                }
            })
        }
    });
    observer.observe(document, { childList: true, subtree: true });
}