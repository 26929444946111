import * as EBML from 'ts-ebml';
import { MediaStreamRecorder } from 'recordrtc';
import { Video } from '../../models';
import { createMetadataAsync, encrypt } from '../util/video-util';
import { FrameTransformer } from './frame-transformer';

export class VideoRecorderOptions {
    public type: string;
    public mimeType: string;
    public extension: string;
    public disableLogs: boolean;
    public ignoreMutedMedia: boolean;
    public bitsPerSecond: number;
    public timeSlice: number;
}

export type RecordingStartedCallback = (capturingStartDate:Date) => void;

export class VideoRecorder {
    private recorder: MediaStreamRecorder;
    private frameTransformer: FrameTransformer;

    constructor(private tabId: number, private videoOptions: VideoRecorderOptions) {
    }

    public setMaskedRegions(regions: DOMRect[]) {
        if (this.frameTransformer) {
            this.frameTransformer.maskedRegions = regions;
        }
    }

    public start(stream: MediaStream, onStreamEnded: (capturedVideo: Video) => void, recordingStartedCallback: RecordingStartedCallback = null, openNewTab: boolean = false) {
        const newStream = new MediaStream();
        stream.getTracks().forEach(track => {
            newStream.addTrack(track);
        });

        const videoTrack = newStream.getVideoTracks()[0];
        const trackProcessor = new global.MediaStreamTrackProcessor({ track: videoTrack });
        const trackGenerator = new global.MediaStreamTrackGenerator({ kind: 'video' });

        this.frameTransformer = new FrameTransformer(this.tabId);
        const transformer = new TransformStream(this.frameTransformer);

        trackProcessor.readable.pipeThrough(transformer).pipeTo(trackGenerator.writable);

        const transformedStream = new MediaStream([trackGenerator]);
        this.recorder = new MediaStreamRecorder(transformedStream, this.videoOptions);
        this.recorder.streams = [transformedStream];

        let streamEndedEvent = 'ended';
        if ('oninactive' in transformedStream && !('onended' in transformedStream)) {
            streamEndedEvent = 'inactive';
        }

        transformedStream.addEventListener(streamEndedEvent, async () => {
            stream.getTracks().forEach(function(track) {
                track.stop();
            });
            const capturedVideo = await this.stopAsync();
            onStreamEnded && onStreamEnded(capturedVideo);
        });

        if (openNewTab) {
            window.open(window.location.origin);
        }
        this.recorder.record();
        if (recordingStartedCallback) {
            recordingStartedCallback(new Date());
        }
    }

    public async stopAsync(noMetadataCallback:()=>void = null): Promise<Video> {
        return new Promise((resolve) => {
            if (this.recorder) {
                if (this.recorder.manuallyStopped) {
                    // video recorder already stopped
                    resolve(null);
                } else {
                    this.frameTransformer.stop();
                    this.recorder.stop(async (blob: Blob) => {
                        const videoBlob = await this.onStopRecording(this.recorder.blob || blob, false, noMetadataCallback);
                        const metadata = await createMetadataAsync(videoBlob, this.tabId);
                        resolve(new Video(videoBlob, this.videoOptions.extension, 'video/webm', null, metadata));
                    });
                }
            } else {
                console.warn('video recorder not present!');
                resolve(null);
            }
        });
    }

    public static async encrypt(video: Video, passphrase: string, saltBuffer: Uint8Array, iterations: number): Promise<Video> {
        return encrypt(video, passphrase, saltBuffer, iterations);
    }

    private async onStopRecording(blob: Blob, ignoreGetSeekableBlob: boolean, noMetadataCallback:()=>void = null): Promise<Blob> {
        if (!this.recorder) {
            return null;
        }

        if (!ignoreGetSeekableBlob) {
            const reader = new EBML.Reader();
            const decoder = new EBML.Decoder();
            const tools = EBML.tools;

            const ebmlElms = decoder.decode(await blob.arrayBuffer());
            ebmlElms.forEach(element => reader.read(element));
            reader.stop();

            if (reader.metadatas?.length > 0) {
                const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
                const body = this.recorder.blob.slice(reader.metadataSize);
                const refinedBlob = new Blob([refinedMetadataBuf, body], {
                    type: blob.type
                });

                return await this.onStopRecording(refinedBlob, true);
            } else {
                console.warn('EBML reader metadata is empty');
                if (noMetadataCallback) {
                    noMetadataCallback();
                }
                return await this.onStopRecording(blob, true);
            }
        }

        if (this.recorder.streams) {
            this.recorder.streams.forEach((stream, idx) => {
                stream.getTracks().forEach(track => track.stop());

                if (idx == 0 && typeof stream.onended === 'function') {
                    stream.onended();
                }
            });

            this.recorder.streams = null;
        }

        const resultingBlob: Blob = ignoreGetSeekableBlob ? blob : this.recorder.blob;
        return resultingBlob;
    }
}