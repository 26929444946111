import { mapDefaultValues, StartOptions } from "@shared/models/start-options";
import { CaptureState } from "./models";
import { DefaultLayoutOptions } from "./models/default-layout-options";
import { ShareOptions } from "./models/share-options";

declare var CDNUrl: any;

const INITIAL_SNIPPET = `
<div class="report-issue-link"><!----> __LABEL_TEXT__</div>
`;
const START_CAPTURE_SNIPPET = `
<div class="reset-this start-capture-form">
    <span class="reset-this reporting-steps">Steps for reporting an issue:</span>
    <div class="reset-this reporting-steps-table">
        <div class="reset-this reporting-steps-row">
            <div class="reset-this reporting-steps-number"><div class="reset-this step-number">1</div></div>
            <div class="reset-this reporting-steps-label">Start Capture</div>
        </div>
        <div class="reset-this reporting-steps-row">
            <div class="reset-this reporting-steps-number"><div class="reset-this step-number">2</div></div>
            <div class="reset-this reporting-steps-label">Reproduce the issue which you want to report</div>
        </div>
        <div class="reset-this reporting-steps-row">
            <div class="reset-this reporting-steps-number"><div class="reset-this step-number">3</div></div>
            <div class="reset-this reporting-steps-label">Stop Capture and submit the issue</div>
        </div>
        <button id="jamStartCapture" class="btn-start">
            <img src="${CDNUrl ? "https:" : ""}${CDNUrl}/play.svg" alt="Start" class="play-icon">
            <span>Start Capture</span>
        </button>
    </div>
</div>
`;
const STOP_CAPTURE_SNIPPET = `
<div class="reset-this capturing-in-progress-form"> 
    Once you have completed reproducing the issue click on "STOP CAPTURE" 
    <button id="jamStopCapture" class="btn-stop">
        <img src="${CDNUrl ? "https:" : ""}${CDNUrl}/stop.svg" alt="Stop" class="stop-icon">
        <span> Stop Capture </span>
    </button>
</div>
`;
const SHARE_SNIPPET = `
<div class="reset-this capturing-share-form">
    <div class="capture-success">
        <img src="${CDNUrl ? "https:" : ""}${CDNUrl}/green-check.svg" class="green-check">
        <h4 class="reset-this">Capture Successful!</h4>
    </div>
    <label for="issue-description">Issue Description</label>
    <span class="fje-description-required" style="visibility:hidden;">*</span>
    <textarea id="jamIssueDescription" name="description" required></textarea>
    <button id="jamShare" class="btn-cta btn-share">
        <img src="${CDNUrl ? "https:" : ""}${CDNUrl}/share.svg" alt="share" class="share-icon">
        Submit Issue 
    </button>
    <button id="jamReset" class="btn-cta btn-reset">
        <img src="${CDNUrl ? "https:" : ""}${CDNUrl}/reset.svg" alt="reset" class="reset-icon"> 
        Capture Again 
    </button>
</div>
`;
const RESET_SNIPPET = `
<div>
    <div class="capture-success"> 
        <img src="${CDNUrl ? "https:" : ""}${CDNUrl}/green-check.svg" class="green-check">
        <h4 class="reset-this">Submitted successfully!</h4>
    </div>
    <button id="jamReset" class="btn-cta btn-reset">
        <img src="${CDNUrl ? "https:" : ""}${CDNUrl}/reset.svg" alt="reset" class="reset-icon"> 
        Capture Again 
    </button>
</div>
`;
const BLINKING_DOT_SNIPPET = `
<svg height="11" width="15" class="blinking">
    <circle cx="8" cy="6.5" r="4" fill="red"></circle>
</svg>
`;
const LOADING_SNIPPET = `
<div class="reset-this lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
`;
const SHARING_SNIPPET = `
<div class='reset-this fje-sharing'>
    ${LOADING_SNIPPET}
    <div class='fje-sharing-text'>Sharing...</div>
</div>
`;

let layoutOptions:DefaultLayoutOptions;
let container:HTMLElement;
let isControlsShown = false;
let jam;

export const initDefaultLayout = (options:DefaultLayoutOptions) => {
    initStyle();
    options.containerId = options.containerId ?? 'fje-container';
    options.startOptions = options.startOptions ?? new StartOptions();
    options.startOptions = mapDefaultValues(options.startOptions);
    options.shareOptions = options.shareOptions ?? new ShareOptions();
    layoutOptions = options;
    jam = (window as any)._fiddlerJamEmbedded;
    container = document.getElementById(options.containerId);
    if (!container) {
        console.error('Missing container.');
        return;
    }

    initToggler(jam.state);
    jam.addStateChangedEventListener(newState => {
        if (isControlsShown) {
            toggleControls(newState, true);
        } else {
            initToggler(newState);
        }
    });
}

const toggleControls = (state, shouldShowControls = false) => {
    if (shouldShowControls) {
        initToggler(state);
    }

    if (!isControlsShown) {
        let controlsHTML;
        let initForm;
        if (state === CaptureState.Initialized || jam.state === CaptureState.Starting) {
            controlsHTML = START_CAPTURE_SNIPPET;
            initForm = initStartCaptureForm;
        } else if (state === CaptureState.Started) {
            controlsHTML = STOP_CAPTURE_SNIPPET;
            initForm = initStopCaptureForm;
        } else if (state === CaptureState.Stopped) {
            controlsHTML = SHARE_SNIPPET;
            initForm = initShareForm;
        } else if (jam.state === CaptureState.Sharing) {
            controlsHTML = SHARING_SNIPPET;
            initForm = () => {};
        } else if (state === CaptureState.Shared) {
            controlsHTML = RESET_SNIPPET;
            initForm = initResetForm;
        }
        const controlsDiv = document.createElement('div');
        controlsDiv.className = 'report-issue-form';
        controlsDiv.innerHTML = controlsHTML;
        container.insertAdjacentElement('beforeend', controlsDiv);
        controlsDiv.addEventListener('click', e => {
            e.stopPropagation();
        });
        initForm();
        isControlsShown = true;
    } else {
        initToggler(state);
    }
}

const initToggler = (state) => {
    const labelText = getToggleLabelText(state);
    container.innerHTML = INITIAL_SNIPPET.replace('__LABEL_TEXT__', labelText);
    const reportIssueLink = container.getElementsByClassName('report-issue-link');
    if (state === CaptureState.Started) {
        reportIssueLink[0].classList.add('recording');
    }
    reportIssueLink[0].addEventListener('click', (e) => {
        e.stopPropagation();
        toggleControls(jam.state);
    });
    window.addEventListener('click', () => {
        if (isControlsShown) {
            toggleControls(jam.state);
        }
    });
    isControlsShown = false;
}

const getToggleLabelText = (state) => {
    if (state !== CaptureState.Started) {
        return 'Report Issue';
    } else {
        return `${BLINKING_DOT_SNIPPET} Recording`;
    }
}

const initStartCaptureForm = () => {
    const startButton = document.getElementById('jamStartCapture');
    startButton.addEventListener('click', async () => {
        await jam.start(layoutOptions.startOptions);
    });
}

const initStopCaptureForm = () => {
    const stopButton = document.getElementById('jamStopCapture');
    stopButton.addEventListener('click', async () => {
        await jam.stop();
    });
}

const initShareForm = () => {
    const shareButton = document.getElementById('jamShare');
    shareButton.addEventListener('click', async () => {
        const requiredEl = document.getElementsByClassName('fje-description-required');
        requiredEl[0].setAttribute('style', 'visibility:hidden;');
        const logDescriptionEl = document.getElementById('jamIssueDescription');
        const logDescription = (logDescriptionEl as HTMLTextAreaElement).value;
        if (!logDescription) {
            requiredEl[0].setAttribute('style', '');
            logDescriptionEl.focus();
            return;
        }
        layoutOptions.shareOptions.description = logDescription;
        await jam.share(layoutOptions.shareOptions);
    });
    initResetForm();
}   

const initResetForm = () => {
    const resetButton = document.getElementById('jamReset');
    resetButton.addEventListener('click', (e) => {
        e.stopPropagation();
        jam.reset();
    });
}

const initStyle = () => {
    var cssId = 'fje-default-layout-style';
    if (!document.getElementById(cssId))
    {
        var head  = document.getElementsByTagName('head')[0];
        var link  = document.createElement('link');
        link.id   = cssId;
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = `${CDNUrl ? "https:" : ""}${CDNUrl}/defaultLayout.min.css`;
        link.media = 'all';
        head.appendChild(link);
    }
}