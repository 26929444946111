import { SensitiveCheckRegex } from "./sensitive-check-regex.model";

export const CausePlaceholder = '{{CAUSE}}';
export const SensitiveValueMask = '!!! Potentially sensitive {{CAUSE}} !!!';
export const GenericSensitiveMask = '!!! Potentially sensitive !!!';

export const JSONMimeTypeRegex = /application\/.*json(-.*)*$/i;
export const XMLMimeTypeRegex = /application\/.*xml(-.*)*$/i;
export const TextMimeTypeRegex = /text\/.*$/i;
export const ImageMimeTypeRegex = /image\/.*$/i;

export const JSONKeyValuePairRegex = /"([^"]+)"\s*:\s*"([^"]+)",?/g;
export const XMLKeyValuePairRegex = /<([^<>]+)>(.+)<\/\1>/gs;
export const UrlEncodedKeyValuePairRegex = /([^?#&=\n<>]+)=([^#&=\n<>]+)\s*/g;
export const MultipartFormDataKeyValuePairRegex = '\\s*\\n[C|c]ontent-[D|d]isposition:\\s*form-data;\\s*name="?([^=\\n]+)"?\\s*\\n';

export const SensitiveKeyRegexes: Array<SensitiveCheckRegex> = [
    new SensitiveCheckRegex(/rsa|dsa|ed25519|ecdsa/i, "Private Key"),
    new SensitiveCheckRegex(/private|key|pass|pwd|secret|credential|token|ssh|auth|contrasena/i, "Secret"),
    new SensitiveCheckRegex(/card|credit|debit|mastercard|visa|discover|diners|american.?express|amex|carte|karte|carta|atm|tarjeta/i, "Card Information"),
    new SensitiveCheckRegex(/cvv|cvc|verification|security|transaction|sicherheit|sicurezza|seguranca|securite/i, "Card Information")
];

export const SensitiveValueRegexes: Array<SensitiveCheckRegex> = [
    //Slack Token
    new SensitiveCheckRegex(/(xox[pborsa]-[0-9]{12}-[0-9]{12}-[0-9]{12}-[a-z0-9]{32})/, "Slack Token"),
    //RSA private key
    new SensitiveCheckRegex(/-----BEGIN RSA PRIVATE KEY-----/, "RSA private key"),
    //SSH (DSA) private key
    new SensitiveCheckRegex(/-----BEGIN DSA PRIVATE KEY-----/, "DSA private key"),
    //SSH (EC) private key
    new SensitiveCheckRegex(/-----BEGIN EC PRIVATE KEY-----/, "EC private key"),
    //SSH (OPENSSH) private key
    new SensitiveCheckRegex(/-----BEGIN OPENSSH PRIVATE KEY-----/, "OPENSSH private key"),
    //PGP private key block
    new SensitiveCheckRegex(/-----BEGIN PGP PRIVATE KEY BLOCK-----/, "PGP private key"),
    //AWS Access Key ID
    new SensitiveCheckRegex(/^((?:A3T[A-Z0-9]|AKIA|AGPA|AIDA|AROA|AIPA|ANPA|ANVA|ASIA)[A-Z0-9]{16})$/, "AWS Access Key ID"),
    //Amazon MWS Auth Token
    new SensitiveCheckRegex(/amzn\.mws\.[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, "Amazon MWS Auth Token"),
    //AWS API Key
    new SensitiveCheckRegex(/^AKIA[0-9A-Z]{16}$/, "AWS API Key"),
    //AWS AppSync GraphQL Key
    new SensitiveCheckRegex(/^da2-[a-z0-9]{26}$/, "AWS AppSync GraphQL Key"), 
    //Facebook Access Token
    new SensitiveCheckRegex(/^EAA[0-9A-Za-z]+$/, "Facebook Access Token"),
    //GitHub
    new SensitiveCheckRegex(/[gG][iI][tT][hH][uU][bB].*['|"]?[0-9a-zA-Z]{35,40}['|"]?/, "GitHub"),
    //GitHub Access Token
    new SensitiveCheckRegex(/^ghp_[0-9a-zA-z]{35,40}$/, "GitHub"),
    //Generic API Key
    new SensitiveCheckRegex(/[aA][pP][iI]_?[kK][eE][yY].*['|"]?[0-9a-zA-Z-_|]{32,45}['|"]?/, "Generic API Key"),
    //Generic Secret
    new SensitiveCheckRegex(/[sS][eE][cC][rR][eE][tT].*['|"]?[0-9a-zA-Z]{32,45}['|"]?/, "Generic Secret"),
    //Google API Key/Google Drive API Key/Google Gmail API Key/Google YouTube API Key"
    new SensitiveCheckRegex(/^AIza[0-9A-Za-z\-_]{35}$/, "Google API Key"),
    //Google Cloud Platform OAuth
    new SensitiveCheckRegex(/[0-9]+-[0-9A-Za-z_]{32}\.apps\.googleusercontent\.com/, "Google Cloud Platform OAuth"),
    //Google Drive OAuth
    new SensitiveCheckRegex(/[0-9]+-[0-9A-Za-z_]{32}\.apps\.googleusercontent\.com/, "Google Drive OAuth"),
    //Google (GCP) Service-account
    new SensitiveCheckRegex(/"type": "service_account"/, "Google (GCP) Service-account"),
    //Google Gmail OAuth
    new SensitiveCheckRegex(/[0-9]+-[0-9A-Za-z_]{32}\.apps\.googleusercontent\.com/, "Google Gmail OAuth"),
    //Google OAuth Access Token
    new SensitiveCheckRegex(/ya29\\.[0-9A-Za-z\-_]+/, "Google OAuth Access Token"),
    //Google YouTube OAuth
    new SensitiveCheckRegex(/[0-9]+-[0-9A-Za-z_]{32}\.apps\.googleusercontent\.com/, "Google YouTube OAuth"),
    //MailChimp API Key
    new SensitiveCheckRegex(/^[0-9a-f]{32}-us[0-9]{1,2}$/, "MailChimp API Key"),
    //Mailgun API Key
    new SensitiveCheckRegex(/key-[0-9a-zA-Z]{32}/, "Mailgun API Key"),
    //Mailgun API Key 2
    new SensitiveCheckRegex(/^[a-f0-9]{32}-[a-f0-9]{8}-[a-f0-9]{8}$/, "Mailgun API Key"),
    //Password in URL
    new SensitiveCheckRegex(/[a-zA-Z]{3,10}:\/\/[^/\s:@]{3,20}:[^/\s:@]{3,20}@.{1,100}/, "Password in URL"),
    //PayPal Braintree Access Token
    new SensitiveCheckRegex(/access_token\$(production|sandbox|testing)(\$[0-9a-z]{16})?\$[0-9a-f]{32}/, "PayPal Braintree Access Token"),
    //Slack Webhook
    new SensitiveCheckRegex(/https:\/\/hooks\.slack\.com\/services\/T[a-zA-Z0-9_]{8}\/B[a-zA-Z0-9_]{8}\/[a-zA-Z0-9_]{24}/, "Slack Webhook"),
    //Stripe API Key
    new SensitiveCheckRegex(/[a-z]k_live_[0-9a-zA-Z]{24,45}/, "Stripe API Key"),
    //Square Access Token
    new SensitiveCheckRegex(/sq0atp-[0-9A-Za-z\-_]{22}/, "Square Access Token"),
    //Square OAuth Secret
    new SensitiveCheckRegex(/sq0cs[a-z]-[0-9A-Za-z\-_]{43}/, "Square OAuth Secret"),
    //Telegram Bot API Key
    new SensitiveCheckRegex(/[0-9]+:AA[0-9A-Za-z\-_]{33}/, "Telegram Bot API Key"),
    //Twilio API Key
    new SensitiveCheckRegex(/^SK[0-9a-fA-F]{32}$/, "Twilio API Key"),
    //Twitter Access Token
    new SensitiveCheckRegex(/[tT][wW][iI][tT][tT][eE][rR].*[1-9][0-9]+-[0-9a-zA-Z]{40}/, "Twitter Access Token"),
    //Twitter OAuth
    new SensitiveCheckRegex(/^[1-9][ 0-9]+-[0-9a-zA-Z]{40}$/, "Twitter OAuth"),
    //Twitter OAuth 2
    new SensitiveCheckRegex(/oauth_consumer_key=[0-9a-zA-Z]{18,45}/, "Twitter OAuth"),
    //Twitter Bearer Token
    new SensitiveCheckRegex(/^[A]{21}.*=[a-zA-Z0-9]{50}$/, "Twitter Access Token"),
    //Facebook OAuth
    new SensitiveCheckRegex(/^[A-Za-z0-9]{125}$/, "Facebook OAuth"),
    //Facebook OAuth 2
    new SensitiveCheckRegex(/[f|F][a|A][c|C][e|E][b|B][o|O][o|O][k|K].{0,30}[A-Za-z0-9]{32}/, "Facebook OAuth"),
    //Master Card
    new SensitiveCheckRegex(/^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/, "Master Card"),
    //Master Card with spaces
    new SensitiveCheckRegex(/^5[1-5][0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20)) [0-9]{4} [0-9]{4} [0-9]{4}$/, "Master Card"),
    //American Express
    new SensitiveCheckRegex(/^3[47][0-9]{13}$/, "American Express"),
    //American Express with spaces
    new SensitiveCheckRegex(/^3[47][0-9]{2} [0-9]{6} [0-9]{5}$/, "American Express"),
    //American Express with spaces 2
    new SensitiveCheckRegex(/^3[47][0-9]{2} [0-9]{4} [0-9]{4} [0-9]{3}$/, "American Express"),
    //Visa Credit card
    new SensitiveCheckRegex(/^4[0-9]{12}(?:[0-9]{3})?$/, "Visa Credit Card"),
    //Visa Credit card with spaces
    new SensitiveCheckRegex(/^4[0-9]{3} [0-9]{4} [0-9]{4} [0-9](?:[0-9]{3})?$/, "Visa Credit Card"),
    //BCGlobal card
    new SensitiveCheckRegex(/^(6541|6556)[0-9]{12}$/, "BC Global Card"),
    //Discover Credit Card
    new SensitiveCheckRegex(/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/, "Discover Credit Card"),
    //Maestro Credit Card
    new SensitiveCheckRegex(/^(5018|5080|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645)[0-9]{8,15}$/, "Maestro Credit Card"),
    //Maestro Credit Card with spaces
    new SensitiveCheckRegex(/^(5018|5080|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645) [0-9]{4} [0-9]{4} [0-9]{0,7}$/, "Maestro Credit Card"),
    //JCB Credit Card
    new SensitiveCheckRegex(/^(?:2131|1800|35[0-9]{3})[0-9]{11,12}$/, "JCB Credit Card"),
    //JCB Credit Card with spaces
    new SensitiveCheckRegex(/^(?:2131|1800|35[0-9]{2}) [0-9]{4} [0-9]{4} [0-9]{3,4}$/, "JCB Credit Card"),
    //Diner's club Credit Card
    new SensitiveCheckRegex(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/, "Diner's Club Credit Card"),
    //Diner's club Credit Card with spaces
    new SensitiveCheckRegex(/^3(?:0[0-5]|[68][0-9])[0-9] [0-9]{4} [0-9]{4} [0-9]{2}$/, "Diner's Club Credit Card"),
    //Amex card
    new SensitiveCheckRegex(/^3[47][0-9]{13}$/, "American Express"),
    //Carte Blanche Card
    new SensitiveCheckRegex(/^389[0-9]{11}$/, "Carte Blanche Card"),
    //Insta Payment Card
    new SensitiveCheckRegex(/^63[7-9][0-9]{13}$/, "Insta Payment Card"),
    //Korean Local Card
    new SensitiveCheckRegex(/^9[0-9]{15}$/, "Korean Local Card"),
    //Laser card
    new SensitiveCheckRegex(/^(6304|6706|6709|6771)[0-9]{12,15}$/, "Laser Card"),
    //Maestro card 2
    new SensitiveCheckRegex(/^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/, "Maestro Card"),
    //Mastercard 2
    new SensitiveCheckRegex(/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/, "Master Card"),
    //Solo card
    new SensitiveCheckRegex(/^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/, "Solo Card"),
    //Switch card
    new SensitiveCheckRegex(/^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/, "Switch Card"),
    //Union pay card
    new SensitiveCheckRegex(/^(62[0-9]{14,17})$/, "Union Pay Card"),
    //Union pay card with spaces
    new SensitiveCheckRegex(/^(62|81)[0-9]{2} [0-9]{4} [0-9]{4}( [0-9]{2,4})?( [0-9]{2,4})?$/, "Union Pay Card"),
    //Vista master card
    new SensitiveCheckRegex(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/, "Vista master card"),
    //Rupay Debit Card
    new SensitiveCheckRegex(/^6[0-9]{15}$/, "Rupay Debit Card"),
    //Rupay Debit Card with spaces
    new SensitiveCheckRegex(/^6[0-9]{3} [0-9]{4} [0-9]{4} [0-9]{4}$/, "Rupay Debit Card")
];

export const AuthHeadersRegexes: Array<RegExp> = [
    /Authorization/i,
    /WWW-Authenticate/i,
    /Proxy-Authorization/i,
    /Proxy-Authenticate/i
];