import { MessageCommands } from "./message-commands";
import { IMessage } from "./message.model";

export class EnvironmentInfoMessage implements IMessage {
    command: MessageCommands = MessageCommands.CollectEnvironmentInfo;

    extensionVersion: string;
    appVersion: string;
    userAgent: string;
    screenWidth: number;
    screenHeight: number;
    availableWidth: number;
    availableHeight: number;
    screenDPI: number;
    devicePixelRatio: number;
    isRetinaDisplay: boolean;
    timezone: string;
    locale: string;
}