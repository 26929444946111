export enum MessageCommands {
    Initialize = 'initialize',
    Pong = 'pong',
    Click = 'click',
    Key = 'key',
    Input = 'input',
    Scroll = 'scroll',
    CollectEnvironmentInfo = 'collectEnvironmentInfo',
    DomEvent = 'dom-event',
    MaskedRegions = 'masked-regions',
    MaskedRegionsZoomFactor = 'masked-regions-zoom-factor',
    StorageInfo = 'storageInfo',
    FontsInfo = 'fontsInfo',
    Share = 'share',
    ShareCompleted = 'shareCompleted',
    StartCapture = 'startCapture',
    StopCapture = 'stopCapture',
    ClearCapture = 'clearCapture',
    PreviewCapture = 'previewCapture',
    ClearSelectedFile = 'clearSelectedFile',
    SessionAdded = 'sessionAdded',
    SessionUpdated = 'sessionUpdated',
    DownloadHar = 'downloadHar',
    ReplyHar = 'replyHar',
    StartMocking = 'startMocking',
    StopMocking = 'stopMocking',
    MockState = 'mockState',
    UploadProgress = 'uploadProgress',
    MessageChunk = 'messageChunk',
    AuthTokens = 'authTokens',
    FrameInfo = 'collectFrameInfo',
    RequestFrameIds = 'requestFrameIds',
    SendCustomFrameId = 'sendCustomFrameId',
    ReAttachScript = 'reAttachScript',
    LoadHarFromBrowser = 'loadHarFromBrowser',
    StartFromSDK = 'startFromSDK',
    StopFromSDK = 'stopFromSDK',
    Analytics = 'analytics',
    CloseExpiredTab = 'closeExpiredTab',
    CloseCurrentTab = 'closeCurrentTab',
    ActiveTabStatusUpdated = 'activeStatusTabUpdated',
    SwitchToCapturingTab = 'switchToCapturingTab',
    PreloadCaptureSettings = 'preloadCaptureSettings',
    RequestPredefinedCaptureSettings = 'requestPredefinedCaptureSettings',
    ResetPredefinedCaptureSettings = 'resetPredefinedCaptureSettings',
    ClearBrowsingDataStarted = 'clearBrowsingDataStarted',
    ClearBrowsingDataFinished = 'clearBrowsingDataFinished',
    Websocket = 'websocket',
    Navigation = 'navigation'
}