
import { snapshot } from 'rrweb-snapshot';

declare var CDNUrl: any;

const screenCaptureIframeId = '_fiddlerJamScreenCapture';

export const initScreenCapture = (handler:(dataUrl:string, t:string)=>Promise<void>) => {
    const iframe = document.createElement('iframe');
    iframe.id = screenCaptureIframeId;
    iframe.src = `${CDNUrl ? "https:" : ""}${CDNUrl}/screen-capture.html`;
    iframe.style.visibility = 'hidden';
    iframe.style.width = "100%";
    iframe.style.height = `${document.body.clientHeight}px`;
    document.body.appendChild(iframe);
    var iframeContent = iframe.contentDocument || iframe.contentWindow.document;
    iframeContent.body.setAttribute('style', 'width: 100%');

    window.onmessage = e => {
        if (e.data.type == 'SCREEN_CAPTURE') {
            handler(e.data.imageData, e.data.timeString);
        }
    };
}

export const captureScreen = async (clientX:number, clientY:number, rect:DOMRect, t:string) => {
    const screenCaptureIframe = document.getElementById(screenCaptureIframeId) as HTMLIFrameElement;
    const htmlSnapshot = snapshot(document, {
        inlineImages: true,
        inlineStylesheet: true
    });
    const message = {
        type: 'SCREEN_CAPTURE',
        timeString: t,
        html: JSON.parse(JSON.stringify(htmlSnapshot[0])),
        height: document.body.clientHeight,
        clientX,
        clientY,
        rect
    };
    screenCaptureIframe.contentWindow.postMessage(message, '*');
}