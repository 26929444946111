export enum MessageType {
    Unknown = 'UNKNOWN',
    StartCapture = 'START_CAPTURE',
    StopCapture = 'STOP_CAPTURE',
    ShareLog = 'SHARE_LOG',
    CheckCapturingEnabled = 'CHECK_CAPTURING_ENABLED',
    CaptureOptions = 'CAPTURE_OPTIONS',
    EnvironmentInfo = 'ENVIRONMENT_INFO',
    ConsoleLog = 'CONSOLE_LOG',
    StorageInfo = 'STORAGE_INFO',
    ClickEvent = 'CLICK_EVENT',
    KeyEvent = 'KEY_EVENT',
    InputEvent = 'INPUT_EVENT',
    ScrollEvent = 'SCROLL_EVENT',
    Websocket = 'WEBSOCKET',
    Navigation = 'NAVIGATION',
    AppVersion = 'APP_VERSION',
    ResetDomEvents = 'RESET_DOM_EVENTS',
    DomEvent = 'DOM_EVENT',
    GetDomEvents = 'GET_DOM_EVENTS',
    ScreenshotEvent = 'SCREENSHOT_EVENT'
}