import { DataMasker } from "@shared/content/data-masker";
import { Video } from "@shared/models";
import { MaskedRegionsMessage, MaskedRegionsZoomFactorMessage } from "@shared/models/messages";
import { StartOptions } from "@shared/models/start-options";
import { BaseVideoRecorder, RecordingStartedCallback } from "@shared/services/video/base-video-recorder";
import { VideoRecorder, VideoRecorderOptions } from "@shared/services/video/recorder";

export class DisplayVideoRecorder implements BaseVideoRecorder {
    private video: Video;
    private videoRecorder:VideoRecorder;
    private dataMasker:DataMasker;
    private maskedRegions: Record<string, DOMRect[]>;
    private zoomFactor: number | null;

    constructor(private tabId: number, private startOptions:StartOptions, private videoOptions: VideoRecorderOptions, private onStreamEnded: (capturedVideo: Video) => void) {
        this.videoRecorder = new VideoRecorder(tabId, videoOptions);
        if (startOptions.maskSensitiveData && startOptions.maskSelector) {
            this.maskedRegions = {};
            this.zoomFactor = null;
            this.dataMasker = new DataMasker(startOptions.maskSelector, m => {
                if (m instanceof MaskedRegionsMessage) {
                    this.processMaskedRegions(m);
                } else if (m instanceof MaskedRegionsZoomFactorMessage) {
                    this.processMaskedRegionsZoomFactor(m);
                }
            });
        }
    }

    public async startAsync(recordingStartedCallback: RecordingStartedCallback, openNewTab: boolean): Promise<void> {
        this.video = null;

        const constraints = {
            video: true,
            preferCurrentTab: true,
            audio: false
        };
        const stream = await navigator.mediaDevices.getDisplayMedia(constraints);
        this.dataMasker && this.dataMasker.start();
        this.videoRecorder.start(stream, this.onStreamEnded, recordingStartedCallback, openNewTab);
    }

    public async stopAsync(): Promise<void> {
        this.video = await  this.videoRecorder.stopAsync();
        this.dataMasker && this.dataMasker.stop();
    }

    public async getVideoAsync(sessionDurationInSeconds?: number): Promise<Video> {
        return Promise.resolve(this.video);
    }
    
    private async processMaskedRegions(message: MaskedRegionsMessage) {
        const filteredRects: DOMRect[] = message.rects.filter(rect => rect.x !== 0 || rect.y !== 0 || rect.height !== 0 || rect.width !== 0).map(rect => new DOMRect(
            rect.x * this.zoomFactor,
            rect.y * this.zoomFactor,
            rect.width * this.zoomFactor,
            rect.height * this.zoomFactor));

        if (!message.frameId && filteredRects.length < 1) {
            // This is a reset masked regions message
            this.maskedRegions = {};
            this.videoRecorder.setMaskedRegions([]);
            return;
        }

        this.maskedRegions[message.frameId] = filteredRects;

        if (filteredRects.length < 1) {
            delete this.maskedRegions[message.frameId];
        }

        Object.keys(this.maskedRegions).forEach(frameId => {
            if (this.maskedRegions[frameId].length < 1) {
                delete this.maskedRegions[frameId];
            }
        });

        const regions = Object.keys(this.maskedRegions).reduce((acc, frameId) => this.maskedRegions[frameId].concat(acc), []);
        this.videoRecorder.setMaskedRegions(regions);
    }

    private processMaskedRegionsZoomFactor(message: MaskedRegionsZoomFactorMessage) {
        this.zoomFactor = message.zoomFactor;
    } 
}