import { CaptureSettings } from './';
import { isFirefox, isSafari } from '../services/util/browser-util';
import { VIDEO_MASKING_SELECTOR } from '../services/util/video-masking-selector';

export class StartOptions {
    constructor(public captureScreenshots = true,
        public captureConsole = true,
        public captureStorage = true,
        public captureVideo = true,
        public videoFormat:('dom'|'pixel-perfect') = 'dom',
        public openNewTab = false,
        public reloadPage = false,
        public maskSensitiveData = true,
        public maskSelector: string = null) {
    }
}

export const mapDefaultValues = (options: StartOptions): StartOptions => {
    if (options.captureScreenshots === undefined) {
        options.captureScreenshots = true;
    }
    if (options.captureConsole === undefined) {
        options.captureConsole = true;
    }
    if (options.captureStorage === undefined) {
        options.captureStorage = true;
    }
    if (options.captureVideo === undefined) {
        options.captureVideo = true;
    }
    if (options.videoFormat === undefined) {
        options.videoFormat = 'dom';
    }
    if (options.openNewTab === undefined) {
        options.openNewTab = false;
    }
    if (options.reloadPage === undefined) {
        options.reloadPage = false;
    }
    if (options.maskSensitiveData === undefined) {
        options.maskSensitiveData = true;
    }
    if (options.maskSelector === undefined) {
        options.maskSelector = options.maskSensitiveData ? VIDEO_MASKING_SELECTOR : null;
    }
    if (options.captureVideo && options.videoFormat === 'pixel-perfect' && (isSafari || isFirefox)) {
        console.log('Pixel-perfect is not supported. Fallback to dom video format.');
        options.videoFormat = 'dom';
    }
    if (isSafari) {
        options.captureScreenshots = false;
    }
    return options;
}

export const mapCaptureSettings = (options:StartOptions):CaptureSettings => {
    const settings = new CaptureSettings(
        options.captureScreenshots,
        options.captureConsole,
        false,
        options.maskSensitiveData,
        false,
        options.captureStorage,
        options.captureVideo,
        options.videoFormat === 'dom',
        false,
        false,
        options.reloadPage);

    return settings;
}