import { MessageCommands } from './message-commands';
import { IMessage } from './message.model';

export class InputMessage implements IMessage {
    command: MessageCommands = MessageCommands.Input;

    timeString: string;
    tag: string;
    elementClass: string;
    elementId: string;
}