import { ScrollMessage } from "../models/messages/scroll-message.model";
import { debounce, EventHandler, getElementClass } from "./common";

let oldScrollPositionX:number = 0;
let oldScrollPositionY:number = 0;
let scrollPositionX:number = null;
let scrollPositionY:number = null;
let scrollDirection:('up' | 'down' | 'left' | 'right') = null;
let scrollElement: any = null;

export const initScrollEvents = (scrollEventHandler:EventHandler) => {
    const scrollHandler = () => {
        const eventMsg = new ScrollMessage();
        eventMsg.scrollDirection = scrollDirection;
        eventMsg.scrollPosition = (eventMsg.scrollDirection === 'up' || eventMsg.scrollDirection === 'down') ? scrollPositionY : scrollPositionX;
        eventMsg.tag = scrollElement.nodeName;
        if(scrollElement !== document) {
            eventMsg.elementClass = getElementClass(scrollElement);
            eventMsg.elementId = scrollElement.id;
        }
    
        eventMsg.timeString = new Date().toISOString();
        scrollEventHandler(eventMsg);
    };

    const debouncedScrollHandler = debounce(scrollHandler, 1000);

    const handler = (event:any) => {
        if (scrollElement !== event.target) {
            oldScrollPositionY = 0;
            oldScrollPositionX = 0;
        }
        scrollElement = event.target;
        const scrollY = event.target === document ? (window.pageYOffset || document.documentElement.scrollTop) : (scrollElement.scrollTop || 0);
        const scrollX = event.target === document ? (window.pageXOffset || document.documentElement.scrollLeft) : (scrollElement.scrollLeft || 0);
        scrollPositionY = oldScrollPositionY;
        scrollPositionX = oldScrollPositionX;
        scrollDirection = (scrollY != oldScrollPositionY) ?
            (scrollY > oldScrollPositionY ? 'down' : 'up') : (scrollX > oldScrollPositionX ? 'right' : 'left');
        oldScrollPositionX = scrollX;
        oldScrollPositionY = scrollY;
        debouncedScrollHandler();
    };

    document.addEventListener('scroll', handler, true);
}