import { Video, VideoMetadata } from "../../models";
import * as EBML from 'ts-ebml';
import { WebmEncryptor } from "../video/webm-encrypt";

export const createMetadataAsync = async (blob: Blob, tabId: number, recordingStartedDate:Date = undefined): Promise<VideoMetadata>  => {
    const reader = new EBML.Reader();
    const decoder = new EBML.Decoder();
    const ebmlElms = decoder.decode(await blob.arrayBuffer());
    ebmlElms.forEach(element => reader.read(element));
    reader.stop();

    const metadata: VideoMetadata = {
        tabId: tabId,
        duration: reader.duration,
        recordingStartedDate: recordingStartedDate
    };

    reader.cues.forEach((cue, index) => {
        if (index == 0 && cue.CueTime == 0) {
            metadata.init = {
                offset: 0,
                size: cue.CueClusterPosition
            };

            metadata.media = [];
        }

        if (index < reader.cues.length - 1) {
            if (!metadata.media) {
                metadata.media = [];
            }
            metadata.media.push({
                offset: cue.CueClusterPosition,
                size: reader.cues[index + 1].CueClusterPosition - cue.CueClusterPosition,
                timecode: cue.CueTime / 1000
            });
        } else {
            if (!metadata.media) {
                metadata.media = [];
            }
            metadata.media.push({
                offset: cue.CueClusterPosition,
                size: blob.size - cue.CueClusterPosition,
                timecode: cue.CueTime / 1000
            });
        }
    });

    return metadata;
}

export const encrypt = async (video: Video, passphrase: string, saltBuffer: Uint8Array, iterations: number): Promise<Video> => {
    const input = await video.data.arrayBuffer();
    const encrypted = await WebmEncryptor.encrypt(input, passphrase, saltBuffer, iterations);
    const newBlob = new Blob([encrypted.data.buffer], {
        type: video.data.type
    });

    const metadata = await createMetadataAsync(newBlob, video.metadata.tabId);
    metadata.encryptionKeyId = encrypted.keyId;

    return {
        data: newBlob,
        extension: video.extension,
        contentType: video.contentType,
        contentEncoding: null,
        metadata: metadata
    };
}