import { EventHandler } from "../events/common";
import { EnvironmentInfoMessage } from "../models/messages";

export const reportEnvironmentInfo = (handler:EventHandler) => {
    try {
        const eventMsg = new EnvironmentInfoMessage();
        eventMsg.appVersion = window.navigator.appVersion;
        eventMsg.userAgent = window.navigator.userAgent;
        eventMsg.screenWidth = window.screen.width;
        eventMsg.screenHeight = window.screen.height;
        eventMsg.availableWidth = window.screen.availWidth;
        eventMsg.availableHeight = window.screen.availHeight;
        eventMsg.screenDPI = getScreenDpi();
        eventMsg.devicePixelRatio = getDevicePixelRatio();
        eventMsg.isRetinaDisplay = isRetinaDisplay();

        const opts = Intl.DateTimeFormat().resolvedOptions();
        eventMsg.timezone = opts.timeZone;
        eventMsg.locale = opts.locale;

        handler(eventMsg);
    } catch (e) {
        // we don't want to throw exceptions in the website here
    }
}

function getScreenDpi(): number {
    if (!window.matchMedia('(min-resolution: 10dpi)').matches) {
        return null;
    }

    for (let i = 650; i >= 1; i--) {
        if (window.matchMedia(`(min-resolution: ${i}dpi)`).matches) {
            return i;
        }
    }

    return null;
}

function getDevicePixelRatio(): number {
    return window.devicePixelRatio;
}

function isRetinaDisplay(): boolean {
    if (window.devicePixelRatio > 1) {
        return true;
    }

    const mediaQuery = `(-webkit-min-device-pixel-ratio: 1.5),
        (min--moz-device-pixel-ratio: 1.5),
        (-o-min-device-pixel-ratio: 3/2),
        (min-resolution: 1.5dppx)`;

    if (window.matchMedia && window.matchMedia(mediaQuery).matches) {
        return true;
    }

    return false;
}