import { EventHandler } from "../events/common";
import { StorageInfoMessage, StorageType } from "../models/messages";

const onStorageMessageType = '_jam_on_storage';

const isStorageEvent = evt => {
    const data = evt.data;
    return data &&
        data.messageType === onStorageMessageType &&
        typeof data.isLocalStorage !== 'undefined' &&
        typeof data.isSessionStorage !== 'undefined';
};

export const initStorage = (handler:EventHandler, tabId:number = undefined) => {
    try {
        const iframeId = '_jam_on_storage_frame';

        window.addEventListener('message', evt => {
            if (isStorageEvent(evt)) {
                if (evt.data.isLocalStorage === true) {
                    collectAndReportStorageInfoInternal(handler, tabId, localStorage, StorageType.Local, true);
                } else if (evt.data.isSessionStorage === true) {
                    collectAndReportStorageInfoInternal(handler, tabId, sessionStorage, StorageType.Session, true);
                }
            }
        }, false);

        collectAndReportStorageInfo(handler, tabId);

        if (!document.getElementById(iframeId)) {
            // Because the "storage" event can only be intercepted from the context of another document, we create an iframe
            // which will listen for this event and report it back here: https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
            const iframe = document.createElement('iframe');
            iframe.id = iframeId;
            iframe.style.display = 'none';
            iframe.src = `javascript:void((() => {
            window.addEventListener('storage', evt => {
            window.parent.postMessage({
                messageType: '${onStorageMessageType}',
                isLocalStorage: evt.storageArea === localStorage,
                isSessionStorage: evt.storageArea === sessionStorage
                }, '*');
            }, false);
            })())`;
            document.body.appendChild(iframe);
        }
    } catch {
        // we don't want to throw exceptions in the website here
    }
}

const getStorageInfo = (storage:Storage) => {
    return Object.keys(storage).reduce((obj:any, str) => {
        obj[str] = storage.getItem(str);
        return obj;
    }, { });
}

const collectAndReportStorageInfoInternal = (handler:EventHandler, tabId:number, storage:Storage, type:StorageType, changed:boolean) => {
    try {
        const storageInfo = getStorageInfo(storage);
        const msg = new StorageInfoMessage();
        msg.capturedAt = new Date();
        msg.tabId = tabId;
        msg.type = type;
        msg.changed = changed;
        msg.origin = window.location.origin;
        msg.data = storageInfo;
        
        handler(msg);
    } catch {
        // we don't want to throw exceptions in the website here
    }
}

export const collectAndReportStorageInfo = (handler:EventHandler, tabId:number) => {
  collectAndReportStorageInfoInternal(handler, tabId, localStorage, StorageType.Local, false);
  collectAndReportStorageInfoInternal(handler, tabId, sessionStorage, StorageType.Session, false);
}