import * as rrweb from 'rrweb';
import { eventWithTime, recordOptions } from 'rrweb/typings/types';
import { Video, VideoMetadata } from '../../models';
import { BaseVideoRecorder, RecordingStartedCallback } from './base-video-recorder';
import { StartOptions } from '../../models/start-options';

export class DomVideoRecorder implements BaseVideoRecorder {
    public static readonly CheckoutInterval = 5000;
    private stopDomRecordingFn: () => void;
    private workerUrl: string;
    private worker: Worker;
    private recordingStartedDate: Date;

    constructor(
        private tabId: number,
        private startOptions: StartOptions,
        private onDomEventFn: (event: eventWithTime, isCheckout?: boolean) => Promise<void>,
        private getDomEventsFn: () => Promise<eventWithTime[][]>) {
    }

    public async startAsync(recordingStartedCallback: RecordingStartedCallback, openNewTab: boolean): Promise<void> {
        const options: recordOptions<eventWithTime> = {
            collectFonts: true,
            checkoutEveryNms: DomVideoRecorder.CheckoutInterval,
            maskTextSelector: this.startOptions.maskSelector,
            emit: async (event: eventWithTime, isCheckout?: boolean) => {
                await this.onDomEventFn(event, isCheckout);
            }
        };

        if (this.startOptions.maskSensitiveData &&
            this.startOptions.maskSelector &&
            this.startOptions.maskSelector.length > 0) {
            options.maskTextSelector = this.startOptions.maskSelector;
        }

        this.stopDomRecordingFn = rrweb.record(options);

        // We schedule invisible DOM changes because if there is no activity on the page, the "emit" function
        // is not called every checkout seconds (https://github.com/rrweb-io/rrweb/issues/958) resulting in
        // incorrect video lengths if the sessionDurationInSeconds parameter is specified.
        // The drawback of this approach is that it can lead to a bigger events matrix
        const code = `setInterval(() => postMessage({}), 2500);`;
        const blob = new Blob([code], { type: 'application/javascript' });
        this.workerUrl = URL.createObjectURL(blob);
        this.worker = new Worker(this.workerUrl);
        this.worker.onmessage = () => {
            const element = document.createElement('span');
            document.body.appendChild(element);
            setTimeout(() => document.body.removeChild(element), 2000);
        };

        this.recordingStartedDate = new Date();
        if (recordingStartedCallback) {
            recordingStartedCallback(this.recordingStartedDate);
        }
    }

    public async stopAsync(): Promise<void> {
        this.worker.terminate();
        URL.revokeObjectURL(this.workerUrl);
        this.stopDomRecordingFn();
    }

    public async getVideoAsync(sessionDurationInSeconds?: number): Promise<Video> {
        const encoder = new TextEncoder();

        const eventsMatrix = await this.getDomEventsFn();
        let videoDuration = this.getVideoDuration(eventsMatrix);
        let segmentsToTake = eventsMatrix.length;
        for(let i = 0; i < eventsMatrix.length; i++) {
            const currentSegment = eventsMatrix[i];
            if (!currentSegment || currentSegment.length < 1) {
                segmentsToTake--;
                continue;
            }
            const currentSegmentDuration = currentSegment[currentSegment.length - 1].timestamp - currentSegment[0].timestamp;
            if (videoDuration - currentSegmentDuration < sessionDurationInSeconds * 1000) {
                break;
            } 
            videoDuration -= currentSegmentDuration;
            segmentsToTake--;
        }

        const events: eventWithTime[] = [].concat(...eventsMatrix.slice(-segmentsToTake));
        const data: Uint8Array = encoder.encode(JSON.stringify(events));
        const videoBlob: Blob = new Blob([ data.buffer ], { type: 'application/octet-stream' });
        const metadata: VideoMetadata = {
            tabId: this.tabId,
            encryptionKeyId: null,
            duration: videoDuration,
            recordingStartedDate: this.recordingStartedDate,
            isDomRecording: true
        };
        return new Video(videoBlob, 'json', 'application/json', null, metadata);
    }

    private getVideoDuration(events: eventWithTime[][]): number {
        if (!events || events.length < 1) {
            return 0;
        }

        let duration = 0;
        for (let i = 0; i < events.length; i++) {
            const segment = events[i];
            if (!segment || segment.length < 1) {
                continue;
            }
            const segmentDuration = segment[segment.length - 1].timestamp - segment[0].timestamp;
            duration += segmentDuration;
        }

        return duration;
    }
}