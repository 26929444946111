import { Tools as tools } from './Tools';
import { EbmlTag } from './models/EbmlTag';
import { EbmlElementType } from './models/enums/EbmlElementType';
import { EbmlTagPosition } from './models/enums/EbmlTagPosition';
import { EbmlTagFactory } from './models/EbmlTagFactory';
import { EbmlTagId } from './models/enums/EbmlTagId';

export class EbmlStreamDecoderOptions {
    bufferTagIds?: EbmlTagId[] = [];
}

export class EbmlStreamDecoder {
    private _currentBufferOffset: number = 0;
    private _tagStack: ProcessingTag[] = [];
    private _buffer: Buffer = Buffer.alloc(0);
    private _bufferTagIds: EbmlTagId[] = [];
    private _onData: (tag: EbmlTag) => void;

    constructor(options: EbmlStreamDecoderOptions = {}, onData: (tag: EbmlTag) => void) {
        this._bufferTagIds = options.bufferTagIds || [];
        this._onData = onData;
    }

    async decode(chunk: ArrayBuffer, done: () => void): Promise<void> {
        this._buffer = Buffer.concat([this._buffer, Buffer.from(chunk)]);
        while (await this.parseTags());
        done();
    }

    private async parseTags(): Promise<boolean> {
        const currentTag = this.readTagHeader(this._buffer);
        if (!currentTag) {
            return false;
        }

        if (currentTag.type === EbmlElementType.Master && !this._bufferTagIds.some(i => i === currentTag.id)) {
            this._tagStack.push(currentTag);
            await this.emitTag(currentTag, EbmlTagPosition.Start);
            this.advanceBuffer(currentTag.tagHeaderLength);
            return true;
        } else {
            if (this._buffer.length < currentTag.tagHeaderLength + currentTag.size) {
                return false;
            }

            const data = this._buffer.slice(currentTag.tagHeaderLength, currentTag.tagHeaderLength + currentTag.size);
            await this.emitTag(currentTag, EbmlTagPosition.Content, data);
            this.advanceBuffer(currentTag.tagHeaderLength + currentTag.size);

            while (this._tagStack.length > 0) {
                const nextTag = this._tagStack[this._tagStack.length - 1];
                if (this._currentBufferOffset < (nextTag.absoluteStart + nextTag.tagHeaderLength + nextTag.size)) {
                    break;
                }
                await this.emitTag(nextTag, EbmlTagPosition.End);
                this._tagStack.pop();
            }
        }

        return true;
    }

    private advanceBuffer(length: number): void {
        this._currentBufferOffset += length;
        this._buffer = this._buffer.slice(length);
    }

    private readTagHeader(buffer: Buffer, offset: number = 0): ProcessingTag | null {
        if (buffer.length == 0) {
            return null;
        }
        const tag = tools.readVint(buffer, offset);
        if (tag == null) {
            return null;
        }
        const size = tools.readVint(buffer, offset + tag.length);
        if (size == null) {
            return null;
        }

        const tagIdHex = tools.readHexString(buffer, offset, offset + tag.length)
        const tagId = Number.parseInt(tagIdHex, 16);
        const tagObject = EbmlTagFactory.create(tagId);

        tagObject.size = size.value;

        return Object.assign(tagObject, {
            absoluteStart: this._currentBufferOffset + offset,
            tagHeaderLength: tag.length + size.length
        });
    }

    private async emitTag(tag: ProcessingTag, position: EbmlTagPosition, data?: Buffer): Promise<void> {
        const emittedTag = EbmlTagFactory.create(tag.id);
        emittedTag.size = tag.size;
        emittedTag.position = position;
        if (position === EbmlTagPosition.Content) {
            emittedTag.parseContent(data);
        }
        await this._onData(emittedTag);
    }

}

type ProcessingTag = EbmlTag & {
    absoluteStart: number;
    tagHeaderLength: number;
};