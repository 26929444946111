type Handler = (type:string, message:any, stack:string) => void;

function origin() {
    try {
        throw Error('');
    } catch (err) {
        const stackArray = (err as Error).stack.split('\n');
        stackArray.splice(0, 3);
        return stackArray.join('\n');
    }
}

export const initConsoleLogs = (handler:Handler) => {
    (function(){
        const oldLog = console.log;
        console.log = function (message:any) {
            handler('log', JSON.stringify(message), origin());
            const argumentsTyped: any = arguments;
            oldLog.apply(console, argumentsTyped);
        };
    })();
    (function(){
        const oldLog = console.info;
        console.info = function (message:any) {
            handler('info', JSON.stringify(message), origin());
            const argumentsTyped: any = arguments;
            oldLog.apply(console, argumentsTyped);
        };
    })();
    (function(){
        const oldLog = console.warn;
        console.warn = function (message:any) {
            handler('warning', JSON.stringify(message), origin());
            const argumentsTyped: any = arguments;
            oldLog.apply(console, argumentsTyped);
        };
    })();
    (function(){
        const oldLog = console.error;
        console.error = function (message:any) {
            handler('error', JSON.stringify(message), origin());
            const argumentsTyped: any = arguments;
            oldLog.apply(console, argumentsTyped);
        };
    })();
};