import { MessageCommands } from './message-commands';
import { IMessage } from './message.model';

export class NavigationMessage implements IMessage {
    command: MessageCommands = MessageCommands.Navigation;

    timeString: string;
    type: 'client' | 'server';
    url: string;
    event: string;
    loadTime?:number;
}