import { Workbox } from 'workbox-window';
import { initConsoleLogs } from './console-logs';
import { initErrors } from './errors';
import { initClickEvents } from '@shared/events/clicks';
import { MessageType } from './models/message-type';
import { collectAndReportStorageInfo, initStorage } from '@shared/content/storage';
import { initKeyEvents } from '@shared/events/keys';
import { initInputEvents } from '@shared/events/inputs';
import { IMessage, MessageCommands } from '@shared/models/messages';
import { initScrollEvents } from '@shared/events/scroll';
import { reportEnvironmentInfo } from '@shared/content/environment-info';
import { initWebsockets } from './websockets';
import { initNavigations } from './navigations';
import { CaptureSettings } from '@shared/models';
import { CaptureState } from './models/capture-state';
import { captureScreen, initScreenCapture } from './screenshots';
import { retrieveApiKey, storeApiKey } from './auth-utils';
import { JamEmbeddedVersion } from './models/jam-embedded-version';
import { InitOptions } from './models/init-options';
import { mapDefaultValues, mapCaptureSettings, StartOptions } from '@shared/models/start-options';
import { StateChangedEventHandler } from './models/state-changed-event-handler';
import { initDefaultLayout } from './default-layout';
import { ShareOptions } from './models/share-options';
import { WorkerResponse } from './models/worker-response';
import { WorkerRequest } from './models/worker-request';
import { ErrorEventHandler } from './models/error-event-handler';
import { CaptureDisplayError } from './models/capture-display-error';
import { WorkerError } from './models/worker-error';
import { BaseVideoRecorder, RecordingStartedCallback } from '@shared/services/video/base-video-recorder';
import { VideoRecorderOptions } from '@shared/services/video/recorder';
import { DomVideoRecorder } from '@shared/services/video/dom-video-recorder';
import { Video } from '@shared/models';
import { eventWithTime } from 'rrweb/typings/types';
import { DisplayVideoRecorder } from './display-video-recorder';

declare var AppVersion: any;

const STATE_CHANGED_EVENT_NAME = '_jamStateUpdated';
const ERROR_EVENT_NAME = '_jamError';

export class JamEmbedded {

    private settings: CaptureSettings;
    private wb: Workbox;
    private video: Video;
    private videoRecorder: BaseVideoRecorder;
    private videoOptions: VideoRecorderOptions;
    private isCapturingEnabled: boolean;
    private areEventsInitialized: boolean;
    private keepAliveInterval;

    public state: CaptureState;
    public serviceWorkerPath: string;
    public version: JamEmbeddedVersion;
    public options: StartOptions;

    constructor() {
        this.version = { client: AppVersion };
        this.state = CaptureState.Ready;
        this.isCapturingEnabled = false;
        this.areEventsInitialized = false;
        this.videoOptions = {
            type: 'video',
            mimeType: 'video/webm;codecs="vp9"',
            extension: 'webm',
            bitsPerSecond: 128000,
            disableLogs: true,
            ignoreMutedMedia: false,
            timeSlice: 60 * 60 * 1000 // 1 hour - time slice is set to get around the one hour limit for video recording
        }
    }

    public init(options: InitOptions) {
        const self = this;
        storeApiKey(options.apiKey);
        this.serviceWorkerPath = options.serviceWorkerPath ?? '/service-worker.js';
        const interval = setInterval(function() {
            if(document.readyState === 'complete') {
                clearInterval(interval);
                self.wb = new Workbox(self.serviceWorkerPath);
                self.registerServiceWorker(async () => {
                    window.addEventListener('focus', async () => {
                        await self.initState();
                    });
                    await self.initState();
                    if (options.defaultLayout && options.defaultLayout.enabled) {
                        initDefaultLayout(options.defaultLayout);
                    }
                });
            }
        }, 100);
    }

    /**
     * Start capturing
     * @param options The options for capturing a session - captureScreenshots, captureConsole, captureStorage, captureVideo, openNewTab, reloadPage
    */
    public async start(options: StartOptions) {
        if (this.state !== CaptureState.Initialized) {
            return;
        }
        this.updateState(CaptureState.Starting);
        this.options = mapDefaultValues(options);
        this.settings = mapCaptureSettings(this.options);

        if (this.settings.captureVideo) {
            this.videoRecorder = this.createVideoRecorder();
            if (this.options.videoFormat === 'dom') {
                await this.resetEvents();
            }
        }

        const startCapturing = async (captureStartDate: Date) => {
            console.log('start capturing');
            const swResponse = await this.sendSWMessage({
                type: MessageType.StartCapture,
                payload: {
                    startDate: captureStartDate,
                    title: document.title,
                    options: this.options
                }
            });
            console.log('SW Start Response', swResponse);
            this.isCapturingEnabled = true;
            this.keepAlive();
            this.updateState(CaptureState.Started);
            this.initEvents();
            reportEnvironmentInfo(async (message:IMessage) => {
                if (this.isCapturingEnabled) {
                    const type = this.getMessageType(message.command);
                    await this.sendSWMessage({ type: type, payload: message });
                    if (this.settings.reloadPage) {
                        location.reload();
                    }
                }
            });
            if (options.captureStorage) {
                collectAndReportStorageInfo(async (message: IMessage) => {
                    await this.sendSWMessage({ type: MessageType.StorageInfo, payload: message });
                }, 1);
            }
        };

        if (this.settings.captureVideo && !options.reloadPage) {
            await this.startVideoCapturing(async (captureStartDate: Date) => {
                await startCapturing(captureStartDate);
            });
        } else {
            await startCapturing(new Date());
        }
    }

    public async startVideoCapturing(recordingStartedCallback: RecordingStartedCallback) {
        try {
            await this.videoRecorder.startAsync(recordingStartedCallback, this.options.openNewTab);
        } catch (e) {
            this.throwError(new CaptureDisplayError(e));
            console.log('Starting capturing without video.');
            await recordingStartedCallback(new Date());
        }
    }

    public async stop() {
        if (this.state !== CaptureState.Started) {
            return;
        }
        if (this.settings.captureVideo && this.videoRecorder) {
            await this.videoRecorder.stopAsync();
        }
        const swResponse = await this.sendSWMessage({type: MessageType.StopCapture});
        this.isCapturingEnabled = false;
        this.updateState(CaptureState.Stopped);
        console.log('SW Stop Response', swResponse);
    }

    public async share(options: ShareOptions) {
        if (this.state !== CaptureState.Stopped) {
            return;
        }
        this.updateState(CaptureState.Sharing);
        if (!options) {
            options = new ShareOptions();
        }

        if (options.sessionDurationInSeconds) {
            if (!Number.isInteger(options.sessionDurationInSeconds) || options.sessionDurationInSeconds < 1) {
                throw new Error('The sessionDurationInSeconds parameter must be a valid positive integer');
            }

            if (this.settings.captureVideo && !this.settings.captureDom) {
                throw new Error('The sessionDurationInSeconds parameter can only be used with DOM videos');
            }

            const duration = (options.sessionDurationInSeconds * 1000) / DomVideoRecorder.CheckoutInterval;
            options.sessionDurationInSeconds = (Math.ceil(duration) * DomVideoRecorder.CheckoutInterval) / 1000;
        }

        if (this.settings.captureVideo && this.videoRecorder) {
            this.video = await this.videoRecorder.getVideoAsync(options.sessionDurationInSeconds);
            if (options.sessionDurationInSeconds) {
                options.sessionDurationInSeconds = this.video.metadata.duration / 1000;
            }
        }

        try {
            const apiKey = retrieveApiKey();
            const shareLink = await this.sendSWMessage({
                type: MessageType.ShareLog,
                payload: {
                    apiKey: apiKey,
                    video: this.settings.captureVideo && this.video ? this.video : undefined,
                    ...options
                }
            });
            this.updateState(CaptureState.Shared);
            return shareLink;
        } catch (e) {
            this.updateState(CaptureState.Stopped);
            throw e;
        }
    }

    public async reset() {
        if (this.state !== CaptureState.Stopped && this.state !== CaptureState.Shared) {
            return;
        }
        this.updateState(CaptureState.Initialized);
        if (this.settings.captureVideo) {
            this.videoRecorder = this.createVideoRecorder();
            await this.resetEvents();
        }
    }

    public refreshAppVersion = async () => {
        this.wb.messageSkipWaiting();
        const workerVersion = await this.sendSWMessage({type: MessageType.AppVersion});
        this.version.worker = workerVersion;
    }

    public addStateChangedEventListener = (handler: StateChangedEventHandler) => {
        window.addEventListener(STATE_CHANGED_EVENT_NAME, e => {
            handler(e.detail as CaptureState);
        }, false);
    }

    public addErrorEventListener = (handler: ErrorEventHandler) => {
        window.addEventListener(ERROR_EVENT_NAME, e => {
            handler(e.detail);
        }, false);
    }

    private async initState() {
        this.refreshAppVersion();
        this.isCapturingEnabled = await this.sendSWMessage({type: MessageType.CheckCapturingEnabled});
        if (this.isCapturingEnabled) {
            this.keepAlive();
            this.options = await this.sendSWMessage({type: MessageType.CaptureOptions});
            this.settings = mapCaptureSettings(this.options);
            this.updateState(CaptureState.Started);
            await this.initEvents();
        } else if (this.state !== CaptureState.Stopped && this.state !== CaptureState.Sharing &&
            this.state !== CaptureState.Shared && this.state !== CaptureState.Initialized) {
            this.updateState(CaptureState.Initialized);
        }
    }

    private async initEvents() {
        if (this.isCapturingEnabled && this.settings.captureVideo && !this.videoRecorder) {
            this.videoRecorder = this.createVideoRecorder();
            await this.startVideoCapturing(null);
        }

        if (this.areEventsInitialized) {
            return;
        }

        const sendMessage = async (message: IMessage) => {
            if (this.isCapturingEnabled) {
                const type = this.getMessageType(message.command);
                await this.sendSWMessage({ type: type, payload: message });
            }
        };

        initConsoleLogs(async (type: string, message, stack) => {
            if (this.isCapturingEnabled && this.settings.captureConsole) {
                await this.sendSWMessage({ type: MessageType.ConsoleLog, payload: { message: message, time: new Date(), level: type, stack } });
            }
        });
        initErrors(async e => {
            if (this.isCapturingEnabled && this.settings.captureConsole) {
                await this.sendSWMessage({
                    type: MessageType.ConsoleLog,
                    payload: {
                        message: e.error ? e.error.message : e.message,
                        time: new Date(),
                        level: 'error',
                        stack: e.error ? e.error.stack : `at ${e.filename ? e.filename : '<anonymous>'}:${e.lineno}:${e.colno}`
                    }
                });
            }
        })
        initStorage(async (message:IMessage) => {
            if (this.isCapturingEnabled && this.settings.captureStorageInfo) {
                await this.sendSWMessage({ type: MessageType.StorageInfo, payload: message });
            }
        });
        initClickEvents(sendMessage, (x: number, y: number, r: DOMRect, t: string) => {
            if (this.isCapturingEnabled && this.settings.captureScreenshots) {
                captureScreen(x, y, r, t);
            }
        });
        if (this.isCapturingEnabled && this.settings.captureScreenshots) {
            initScreenCapture(async (dataUrl:string, t:string) => {
                await this.sendSWMessage({ type: MessageType.ScreenshotEvent, payload: { timeString: t, dataUrl: dataUrl } });
            });
        }
        initKeyEvents(sendMessage);
        initInputEvents(sendMessage);
        initScrollEvents(sendMessage);
        initWebsockets(sendMessage);
        initNavigations(sendMessage);
        this.areEventsInitialized = true;
    }

    private registerServiceWorker = async (onServiceWorkerRegistered:()=>void) => {
        const self = this;
        if ('serviceWorker' in navigator) {
            self.wb.register().then(function(registration) {
                if (registration.active && registration.active.state === 'activated' && !navigator.serviceWorker.controller) {
                    window.location.reload();
                    return;
                }

                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope);

                if (onServiceWorkerRegistered) {
                    onServiceWorkerRegistered();
                }
                // detect Service Worker update available and wait for it to become installed
                registration.addEventListener('updatefound', () => {
                    if (registration.installing) {
                        // wait until the new Service worker is actually installed (ready to take over)
                        registration.installing.addEventListener('statechange', () => {
                            if (registration.waiting) {
                                if (navigator.serviceWorker.controller) {
                                    self.wb.messageSkipWaiting();
                                }
                            }
                        })
                    }
                });
            }, function(err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
            });
        }
    }

    private updateState(newState: CaptureState) {
        this.state = newState;
        const captureStateUpdatedEvent = new CustomEvent(STATE_CHANGED_EVENT_NAME, { detail: newState });
        window.dispatchEvent(captureStateUpdatedEvent);
    }

    private throwError(error: Error) {
        const errorEvent = new CustomEvent(ERROR_EVENT_NAME, { detail: error });
        window.dispatchEvent(errorEvent);
    }

    private getMessageType(command: MessageCommands): MessageType {
        switch (command) {
            case MessageCommands.CollectEnvironmentInfo:
                return MessageType.EnvironmentInfo;
            case MessageCommands.Click:
                return MessageType.ClickEvent;
            case MessageCommands.Key:
                return MessageType.KeyEvent;
            case MessageCommands.Input:
                return MessageType.InputEvent;
            case MessageCommands.Scroll:
                return MessageType.ScrollEvent;
            case MessageCommands.Websocket:
                return MessageType.Websocket;
            case MessageCommands.Navigation:
                return MessageType.Navigation;
            default:
                return MessageType.Unknown;
        }
    }

    private async sendSWMessage(message: WorkerRequest) {
        const response: WorkerResponse = await this.wb.messageSW(message);
        if (response.error) {
            const workerError = new WorkerError(response.error);
            this.throwError(workerError);
            throw workerError;
        }
        return response.data;
    }

    private keepAlive() {
        if (this.keepAliveInterval) {
            return;
        }
        this.keepAliveInterval = setInterval(async () => {
            this.isCapturingEnabled = await this.sendSWMessage({type: MessageType.CheckCapturingEnabled});
            if (!this.isCapturingEnabled) {
                clearInterval(this.keepAliveInterval);
            }
        }, 20 * 1000);
    }

    private createVideoRecorder(): BaseVideoRecorder {
        return this.settings.captureDom
            ? new DomVideoRecorder(1, this.options, this.sendDomEvent.bind(this), this.getDomEvents.bind(this))
            : new DisplayVideoRecorder(1, this.options, this.videoOptions, video => this.video = video);
    }

    private async resetEvents(): Promise<void> {
        await this.sendSWMessage({ type: MessageType.ResetDomEvents });
    }

    private async sendDomEvent(event: eventWithTime, isCheckout?: boolean): Promise<void> {
        await this.sendSWMessage({ type: MessageType.DomEvent, payload: { event: event, isCheckout: isCheckout } });
    }

    private async getDomEvents(): Promise<eventWithTime[][]> {
        return await this.sendSWMessage({ type: MessageType.GetDomEvents });
    }
}

export const jam = new JamEmbedded();
(window as any)._fiddlerJamEmbedded = jam;