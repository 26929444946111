export class VideoConfig {
    public mimeType: string;
    public extension: string;
    public bitsPerSecond: number;
    public minFrameRate: number;
    public maxFrameRate: number;
    public maxWidth: number;
    public maxHeight: number;

    constructor() {
        this.mimeType = 'video/webm;codecs="vp9"';
        this.extension = 'webm';
        this.bitsPerSecond = 128000;
        this.minFrameRate = 4;
        this.maxFrameRate = 30;
        this.maxWidth = 3840;
        this.maxHeight = 2160;
    }
}

export class InitSegment {
    public offset: number;
    public size: number;
}

export class MediaSegment extends InitSegment {
    public timecode: number;
}

export class VideoMetadata {
    public tabId: number;
    public encryptionKeyId?: Uint8Array;
    public duration: number;
    public isDomRecording?: boolean;
    public init?: InitSegment;
    public media?: MediaSegment[] = [];
    public recordingStartedDate?: Date;
}


export class Video {
    constructor(public data: Blob, public extension: string, public contentType: string, public contentEncoding: string, public metadata: VideoMetadata) {
    }
}