export const decodeUrl = (value: string): string => {
    try {
        value = decodeURIComponent(value);
    } catch (e) {
        console.warn(`Error decoding ${value}, fallback to unescape`);
        try {
            value = decodeURIComponent(unescape(value));
        } catch (e) {
            console.warn(`Error decoding ${value}`);
        }
    }
    return value;
}

export const base64Encode = (value: string): string => {
    return btoa(value);
}

export const base64Decode = (value: string): string => {
    return atob(value);
}

export function capitalize(value: string): string {
    if (!value) {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}
