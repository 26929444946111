import { EbmlTag } from './models/EbmlTag';
import { EbmlTagPosition } from './models/enums/EbmlTagPosition';
import { EbmlTagId } from './models/enums/EbmlTagId';
import { EbmlMasterTag } from './models/tags/EbmlMasterTag';

export class EbmlStreamEncoder {
    private dataBuffer: Buffer = Buffer.alloc(0);
    private openTags: EbmlMasterTag[] = [];
    private chunks: ArrayBuffer[] = [];

    get buffer(): Uint8Array {
        const result = Uint8Array.from(Array.prototype.concat(...this.chunks.map(a => Array.from(new Uint8Array(a)))));
        return result;
    }

    encode(chunk: EbmlTag) {
        if (chunk) {
            if (!chunk.id) {
                throw new Error(`No id found for ${JSON.stringify(chunk)}`);
            }

            switch (chunk.position) {
                case EbmlTagPosition.Start:
                    this.startTag(<EbmlMasterTag>chunk);
                    break;
                case EbmlTagPosition.Content:
                    this.writeTag(chunk);
                    break;
                case EbmlTagPosition.End:
                    this.endTag(<EbmlMasterTag>chunk);
                    break;

                default:
                    break;
            }
        }
    }

    private bufferAndFlush(buffer: Buffer) {
        this.dataBuffer = Buffer.concat([this.dataBuffer, buffer]);
        this.flush();
    }

    private flush(): void {
        let chunk: Buffer = null;
        if (this.dataBuffer.length > 0) {
            chunk = Buffer.from(this.dataBuffer);
            this.dataBuffer = Buffer.alloc(0);
            this.chunks.push(chunk.buffer);
        }
    }

    private writeTag(tag: EbmlTag): void {
        if (this.openTags.length > 0) {
            this.openTags[this.openTags.length - 1].Children.push(tag);
        } else {
            this.bufferAndFlush(tag.encode());
        }
    }

    private startTag(tag: EbmlMasterTag): void {
        if (this.openTags.length > 0) {
            this.openTags[this.openTags.length - 1].Children.push(tag);
        }
        this.openTags.push(tag);
    }

    private endTag(tag: EbmlMasterTag) {
        const inMemoryTag = this.openTags.pop();
        if (tag.id !== inMemoryTag.id) {
            throw `Logic error - closing tag "${EbmlTagId[tag.id]}" is not expected tag "${EbmlTagId[inMemoryTag.id]}"`;
        }

        if (this.openTags.length < 1) {
            this.bufferAndFlush(inMemoryTag.encode());
        }
    }
}