import { MessageCommands } from "./message-commands";
import { IMessage } from "./message.model";

export enum StorageType {
    Local,
    Session
}

export class StorageInfoMessage implements IMessage {
    command: MessageCommands = MessageCommands.StorageInfo;

    tabId: number;
    origin: string;
    type: StorageType;
    capturedAt: Date;
    changed: boolean;
    data: { [key: string] : string; } = {};
}