import { IMessage } from "../models/messages/message.model";

export type EventHandler = (message:IMessage) => void;

export const debounce = (func:((evt:any)=>void), delay:number) => {
    let timeout:any = null;
    return function(this:any) {
        clearTimeout(timeout);
        const callNow = !timeout;
        timeout = setTimeout(() => timeout = null, delay);
        if (callNow) {
            const typedArguments:any = arguments;
            func.apply(this, typedArguments);
        }
    };
}

export const getElementClass = (element: HTMLElement) => {
    return typeof element.className === 'object' ? null : element.className;
}