import { MessageCommands } from ".";
import { ShareMetadata } from "../share-metadata.model";
import { IMessage } from "./message.model"

export class ShareResultMessage implements IMessage {
    command: MessageCommands = MessageCommands.ShareCompleted;

    constructor(public data: ShareResultState) {
    }
}

export class ShareResultState {
    isSharing: boolean = false;
    shareComplete: boolean = false;
    shareResult: ShareResult = ShareResult.None;
    shareMsg: string = null;
    shareUrl: string = null;
    metadata?: ShareMetadata;
    har?: string = null;

    reset() {
        this.isSharing = false;
        this.shareComplete = false;
        this.shareResult = ShareResult.None;
        this.shareMsg = null;
        this.shareUrl = null;
        this.metadata = null;
        this.har = null;
    }
}

export enum ShareResult {
    None,
    Success,
    Fail
}