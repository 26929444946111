import { MessageCommands } from './message-commands';
import { IMessage } from './message.model';

export class ScrollMessage implements IMessage {
    command: MessageCommands = MessageCommands.Scroll;

    timeString: string;
    scrollDirection: 'up' | 'down' | 'left' | 'right';
    scrollPosition: number;
    tag: string;
    elementClass: string;
    elementId: string;
}