import { MessageCommands } from './message-commands';
import { IMessage } from './message.model';

export class WebsocketMessage implements IMessage {
    command: MessageCommands = MessageCommands.Websocket;

    timeString: string;
    type: 'open' | 'send' | 'receive' | 'close';
    url: string;
    message?: string;
}