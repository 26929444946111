export class CaptureSettings {
    constructor(public captureScreenshots: boolean = true,
                public captureConsole: boolean = true,
                public disableCache: boolean = true,
                public maskPostData: boolean = false,
                public maskCookies: boolean = true,
                public captureStorageInfo: boolean = true,
                public captureVideo: boolean = true,
                public captureDom: boolean = false,
                public clearBrowsingData: boolean = false,
                public captureFonts: boolean = false,
                public reloadPage: boolean = false,
                public isAuto: boolean = false,
                public shareEmails: string[] = []) {
    }
}
