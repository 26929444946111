import { EventHandler } from "@shared/events/common";
import { WebsocketMessage } from "@shared/models/messages";

export const initWebsockets = (handler:EventHandler) => {
    const OriginalWebsocket = window.WebSocket;
    const ProxiedWebSocket = function() {
        const ws = new OriginalWebsocket(arguments[0], arguments.length > 1 ? arguments[1] : undefined);

        ws.addEventListener('message', function (e) {
            const msg = prepareMessage('receive', (e.target as any).url, e.data);
            handler(msg);
        });

        ws.addEventListener('open', function (e) {
            const msg = prepareMessage('open', (e.target as any).url, undefined);
            handler(msg);
        });

        ws.addEventListener('close', function (e) {
            const msg = prepareMessage('close', (e.target as any).url, undefined);
            handler(msg);
        });

        const oldSend = ws.send;
        const proxiedSend = function() {
            const msg = prepareMessage('send', ws.url, arguments[0]);
            handler(msg);
            const argumentsTyped: any = arguments;
            return oldSend.apply(ws, argumentsTyped);
        }
        ws.send = proxiedSend;

        return ws;
    };
    (window as any).WebSocket = ProxiedWebSocket;
}

const prepareMessage = (type: 'open' | 'send' | 'receive' | 'close', url: string, data: any): WebsocketMessage => {
    const msg = new WebsocketMessage();
    msg.type = type;
    msg.timeString = new Date().toISOString();
    msg.url = url;
    if (data && typeof(data) === 'object') {
        msg.message = JSON.stringify(data);
    }
    else {
        msg.message = data;
    }
    return msg;
}