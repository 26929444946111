import { EbmlStreamEncoder, EbmlStreamDecoder, EbmlTag, EbmlTagFactory, EbmlTagId, SimpleBlock, EbmlMasterTag } from './ebml';
import { getKey } from '../util/crypto-util';

export class EncryptedVideo {
    keyId: Uint8Array;
    data: Uint8Array;
}

export class WebmEncryptor {
    public static async encrypt(webmVideo: ArrayBuffer, passphrase: string, saltBuffer: Uint8Array, iterations: number): Promise<EncryptedVideo> {
        const trackIvMap: { [key: number]: Uint32Array } = { };
        const keyBuffer = await getKey(passphrase, saltBuffer, iterations, 128);
        const keyId = crypto.getRandomValues(new Uint8Array(16));

        return new Promise((resolve) => {
            const encoder = new EbmlStreamEncoder();

            const decoder = new EbmlStreamDecoder({ bufferTagIds: [EbmlTagId.TrackEntry] }, async tag => {
                if (tag) {
                    tag = await WebmEncryptor.encryptChunk(keyId, keyBuffer, tag, trackIvMap);
                }

                encoder.encode(tag);
            });

            decoder.decode(webmVideo, () => resolve({
                keyId: keyId,
                data: encoder.buffer
            }));
        });
    }

    private static async encryptChunk(keyId: Uint8Array, keyBuffer: ArrayBuffer, chunk: EbmlTag, trackIvMap: { [key: number]: Uint32Array }): Promise<EbmlTag> {
        if (chunk.id === EbmlTagId.SimpleBlock) {
            await WebmEncryptor.encryptBlockData(keyBuffer, <SimpleBlock>chunk, trackIvMap);
        }

        if (chunk.id === EbmlTagId.TrackEntry) {
            let contentEncodings = <EbmlMasterTag>(<EbmlMasterTag>chunk).Children.find((t: EbmlTag) => t.id === EbmlTagId.ContentEncodings);

            if (!contentEncodings) {
                contentEncodings = EbmlTagFactory.create(EbmlTagId.ContentEncodings);
                (<EbmlMasterTag>chunk).Children.push(contentEncodings);
            }

            contentEncodings.Children.push(WebmEncryptor.getEncryptionContentEncodingTag(keyId));
        }

        return chunk;
    }

    private static getEncryptionContentEncodingTag(keyId: Uint8Array): EbmlTag {
        const encoding = EbmlTagFactory.create(EbmlTagId.ContentEncoding);

        const encodingOrder = EbmlTagFactory.create(EbmlTagId.ContentEncodingOrder);
        encodingOrder.data = 0;
        encoding.Children.push(encodingOrder);

        const encodingScope = EbmlTagFactory.create(EbmlTagId.ContentEncodingScope);
        encodingScope.data = 1;
        encoding.Children.push(encodingScope);

        const encodingType = EbmlTagFactory.create(EbmlTagId.ContentEncodingType);
        encodingType.data = 1;
        encoding.Children.push(encodingType);

        const encryption = EbmlTagFactory.create(EbmlTagId.ContentEncryption);
        encoding.Children.push(encryption);

        const encAlgo = EbmlTagFactory.create(EbmlTagId.ContentEncAlgo);
        encAlgo.data = 5;
        encryption.Children.push(encAlgo);

        const encKeyId = EbmlTagFactory.create(EbmlTagId.ContentEncKeyID);

        encKeyId.data = keyId;
        encryption.Children.push(encKeyId);

        const aesSettings = EbmlTagFactory.create(EbmlTagId.ContentEncAESSettings);
        encryption.Children.push(aesSettings);

        const cipherMode = EbmlTagFactory.create(EbmlTagId.AESSettingsCipherMode);
        cipherMode.data = 1;
        aesSettings.Children.push(cipherMode);

        return encoding;
    }

    private static async encryptBlockData(keyBuffer: ArrayBuffer, simpleBlock: SimpleBlock, trackIvMap: { [key: number]: Uint32Array }): Promise<void> {
        const iv = Buffer.alloc(16);

        let ivCounter = trackIvMap[simpleBlock.track];
        if (!ivCounter) {
            ivCounter = new Uint32Array(2);
            crypto.getRandomValues(ivCounter)
            trackIvMap[simpleBlock.track] = ivCounter;
        }
        iv.writeUInt32BE(ivCounter[0], 0);
        iv.writeUInt32BE(ivCounter[1], 4);

        // Increment IV every frame
        ivCounter[1]++;
        if (ivCounter[1] === 0) {
            ivCounter[0]++;
        }

        const key = await crypto.subtle.importKey(
            'raw',
            keyBuffer,
            'AES-CTR',
            false,
            [ 'encrypt' ]
        );

        const algorithm = {
            name: 'AES-CTR',
            counter: iv.buffer,
            length: 64
        };

        const encrypted = await crypto.subtle.encrypt(algorithm, key, simpleBlock.payload);

        simpleBlock.payload = Buffer.concat([
            Buffer.of(0x01),
            iv.subarray(0, 8),
            new Uint8Array(encrypted)
        ]);
    }
}