import { DOMElement } from '../';
import { MessageCommands } from './message-commands';
import { IMessage } from './message.model';

export class ClickMessage implements IMessage {
    command: MessageCommands = MessageCommands.Click;

    type: string = 'click';
    clientX: number;
    clientY: number;
    userAgent: string;
    //document: Document;
    element: DOMElement;
    timeString: string;
    frameId: string;
    shouldCaptureScreenshot: boolean;
    screenshotDataUrl?: any;
    startedDateTime: Date;
}