
import { EventHandler } from "@shared/events/common";
import { NavigationMessage } from "@shared/models/messages/navigation-message.model";

export const initNavigations = (handler:EventHandler) => {
    //client side navigations
    history.pushState = ( f => function pushState() {
        const msg = prepareMessage('client', arguments.length > 2 ? arguments[2] : arguments[0].path, 'pushState');
        handler(msg);
        const argumentsTyped: any = arguments;
        const ret = f.apply(history, argumentsTyped);
        return ret;
    })(history.pushState);
    
    history.replaceState = ( f => function replaceState() {
        const msg = prepareMessage('client', arguments.length > 2 ? arguments[2] : arguments[0].path, 'replaceState');
        handler(msg);
        const argumentsTyped: any = arguments;
        const ret = f.apply(history, argumentsTyped);
        return ret;
    })(history.replaceState);
    
    window.addEventListener('popstate',() => {
        const msg = prepareMessage('client', location.href, 'popstate');
        handler(msg);
    });

    //server side navigations
    const navs = window.performance.getEntriesByType('navigation');
    for (const nav of navs) {
        const msg = prepareMessage('server', nav.name, 'onload', nav.duration);
        handler(msg);
    }
    window.onbeforeunload = function() {
        const msg = prepareMessage('server', location.href, 'onbeforeunload');
        handler(msg);
    };
};

const prepareMessage = (type: 'client' | 'server', url:string, event:string, loadTime:number = undefined):NavigationMessage => {
    const msg = new NavigationMessage();
    msg.timeString = !loadTime ? new Date().toISOString() : new Date(new Date().getTime() - loadTime).toISOString();
    msg.type = type;
    msg.url = type === 'client' ? `${location.origin}${url}` : url;
    msg.event = event;
    msg.loadTime = loadTime;
    return msg;
}