import uuidv4 from '../services/util/uuid-util';
import { MaskedRegionsMessage, MaskedRegionsZoomFactorMessage } from '../models/messages';
import { EventHandler } from '../events/common';

const FRAME_ID_ATTRIBUTE = 'data-fiddler-jam-frame-id';
const FRAME_COORDINATES_MESSAGE = '_FRAME_COORDINATES_MESSAGE_';

export class DataMasker {
    private frameCoordinatesEvent: (event: MessageEvent<any>) => void;
    private scrollEvent: () => void;
    private resizeEvent: () => void;
    private mutationObserver: MutationObserver;
    private resizeObservers: ResizeObserver[];
    private resolvables: Record<string, (arg: any) => void>;
    private maskedRegions: Array<{ element: HTMLElement, rect: DOMRect }>;
    private frameId: string;
    private offsetX: number;
    private offsetY: number;
    private isRunning: boolean;

    constructor(private selector: string, private handler:EventHandler) {
        this.frameId = uuidv4();

        this.frameCoordinatesEvent = this.onFrameCoordinates.bind(this);
        this.scrollEvent = this.onScroll.bind(this);
        this.resizeEvent = this.reportElements.bind(this);

        if (window.top === window) {
            setTimeout(() => this.resetMaskedRegions(), 1000);
        }
    }

    async start() {
        this.resolvables = {};
        this.maskedRegions = [];
        this.resizeObservers = [];
        this.offsetX = null;
        this.offsetY = null;
        this.sendZoomFactorMessage();
        this.isRunning = true;

        window.addEventListener('message', this.frameCoordinatesEvent);
        window.addEventListener('resize', this.resizeEvent);
        document.addEventListener('scroll', this.scrollEvent, true);

        this.attachObservers();

        await this.pollForElementCoordinates();
    }

    stop() {
        this.isRunning = false;
        this.resizeObservers.forEach(observer => observer.disconnect());
        this.resizeObservers = [];
        this.maskedRegions = [];
        this.resolvables = {};
        this.mutationObserver.disconnect();
        window.removeEventListener('message', this.frameCoordinatesEvent);
        window.removeEventListener('resize', this.resizeEvent);
        document.removeEventListener('scroll', this.scrollEvent);
    }

    private async pollForElementCoordinates() {
        await this.reportElements();
        if (this.isRunning) {
            setTimeout(async () => await this.pollForElementCoordinates(), 20);
        }
    }

    private async reportElements() {
        const elements: HTMLElement[] = Array.from(document.querySelectorAll(this.selector));
        if (elements.length > 0) {
            await this.ensureOffsets();

            this.maskedRegions = elements
                .filter(element => this.isVisibleInViewport(element))
                .map(element => {
                    const rect = element.getBoundingClientRect();

                    rect.x += this.offsetX;
                    rect.y += this.offsetY;

                    return { element, rect };
                });
        } else {
            this.maskedRegions = [];
        }

        this.sendMaskedRegions();
    }

    private sendMaskedRegions() {
        if (!this.isRunning) {
            return;
        }

        this.sendZoomFactorMessage();

        const message = new MaskedRegionsMessage();
        message.frameId = this.frameId;
        message.rects = this.maskedRegions.map(x => x.rect);
        this.handler(message);
    }

    private sendZoomFactorMessage() {
        if (window === window.top) {
            const zoomFactorMessage = new MaskedRegionsZoomFactorMessage();
            // Only report the zoom factor from the top window. On chromium browsers, inside iframe, the window.outerWidth
            // incorrectly returns the width of the entire screen
            zoomFactorMessage.zoomFactor = window.outerWidth / window.innerWidth;
            this.handler(zoomFactorMessage);
        }
    }

    private async onFrameCoordinates(event: MessageEvent<any>) {
        if (!event || !event.data || !event.data.channel) {
            return;
        }

        switch (event.data.channel) {
            case FRAME_COORDINATES_MESSAGE: {
                const coordinates = await this.getFrameCoordinates();
                const targetFrame = Array.from(document.querySelectorAll('iframe')).find(iframe => iframe.contentWindow === event.source);
                if (targetFrame) {
                    targetFrame.setAttribute(FRAME_ID_ATTRIBUTE, event.data.frameId);
                    const { x, y } = targetFrame.getBoundingClientRect();
                    (<any>event.source).postMessage({
                        channel: `${FRAME_COORDINATES_MESSAGE}-REPLY`,
                        id: event.data.id,
                        coordinates: {
                            x: coordinates.x + x,
                            y: coordinates.y + y,
                        }
                    }, '*');
                }
                break;
            }
            case `${FRAME_COORDINATES_MESSAGE}-REPLY`: {
                const resolve = this.resolvables[event.data.id];
                if (resolve) {
                    delete this.resolvables[event.data.id];
                    resolve(event.data.coordinates);
                }
                break;
            }
            case `${FRAME_COORDINATES_MESSAGE}-RECALCULATE`: {
                await this.ensureOffsets(true);
                await this.reportElements();
                this.recalculateFramePositions();
                break;
            }
        }
    }

    private async onScroll() {
        await this.reportElements();
        this.recalculateFramePositions();
    }

    private attachObservers() {
        this.mutationObserver = new MutationObserver((mutations: Array<MutationRecord>) => {
            const addedElements: HTMLElement[] = [];
            const removedElements: HTMLElement[] = [];
            for (const mutation of mutations) {
                const addedNodes: Node[] = Array.from(mutation.addedNodes);
                const removedNodes: Node[] = Array.from(mutation.removedNodes);
                for (const node of addedNodes) {
                    if (node.nodeType === 1 && (<HTMLElement>node).matches(this.selector)) {
                        addedElements.push(<HTMLElement>node);
                    }
                }
                for (const node of removedNodes) {
                    if (node.nodeType === 1) {
                        const htmlElement = <HTMLElement>node;
                        const iframes = Array.from(htmlElement.querySelectorAll('iframe'));
                        if (iframes.length > 0) {
                            iframes.forEach(iframe => {
                                const frameId = iframe.getAttribute(FRAME_ID_ATTRIBUTE);
                                if (frameId && frameId.length > 0) {
                                    const message = new MaskedRegionsMessage();
                                    message.frameId = frameId;
                                    message.rects = [];
                                    setTimeout(() => this.handler(message), 300);
                                }
                            });
                        }

                        if (htmlElement.matches(this.selector)) {
                            removedElements.push(htmlElement);
                        }
                    }
                }
            }

            addedElements.forEach(element => {
                const resizeObserver = new ResizeObserver(() => {
                    if (this.isVisibleInViewport(element)) {
                        const index = this.maskedRegions.findIndex(region => region.element === element);
                        const rect = element.getBoundingClientRect();
                        if (index < 0) {
                            this.maskedRegions.push({ element, rect });
                        } else {
                            this.maskedRegions[index].rect = rect;
                        }
                        this.sendMaskedRegions();
                    }
                });
                resizeObserver.observe(element);
                this.resizeObservers.push(resizeObserver);
            });

            removedElements.forEach(element => {
                const index = this.maskedRegions.findIndex(region => region.element === element);
                if (index > -1) {
                    this.maskedRegions.slice(index, 1);
                }
            });

            if (removedElements.length > 0) {
                this.sendMaskedRegions();
            }
        });

        this.mutationObserver.observe(document.documentElement, { childList: true, subtree: true, attributes: true });
    }

    private recalculateFramePositions() {
        const iframes = Array.from(document.querySelectorAll('iframe'));
        iframes.forEach(iframe => iframe.contentWindow.postMessage({ channel: `${FRAME_COORDINATES_MESSAGE}-RECALCULATE` }, '*'));
    }

    private isVisibleInViewport(element: HTMLElement) {
        const rect = element.getBoundingClientRect();

        let isVisible = (
            (rect.width > 0 && rect.height > 0)
            &&
            (
                ((rect.top < (window.innerHeight || document.documentElement.clientHeight)) && (rect.top > 0 || rect.bottom > 0))
                &&
                ((rect.left < (window.innerWidth || document.documentElement.clientWidth)) && (rect.left > 0 || rect.right > 0))
            )
        );

        if (!isVisible) {
            return false;
        }

        isVisible = !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
        if (!isVisible) {
            return false;
        }

        const style = window.getComputedStyle(element);
        return style.visibility !== 'hidden';
    }


    private async getFrameCoordinates(): Promise<{ x: number, y: number }> {
        if (window.top === window) {
            return Promise.resolve({ x: 0, y: 0 });
        }

        const id = uuidv4();

        return new Promise(resolve => {
            this.resolvables[id] = resolve;

            window.parent.postMessage({
                channel: FRAME_COORDINATES_MESSAGE,
                id: id,
                frameId: this.frameId
            }, '*');

            setTimeout(() => {
                delete this.resolvables[id];
                return resolve({ x: 0, y: 0 });
            }, 2000);
        });
    }

    private async ensureOffsets(force: boolean = false) {
        if (force || this.offsetX === null || this.offsetY === null) {
            const { x, y } = await this.getFrameCoordinates();
            this.offsetX = x;
            this.offsetY = y;
        }
    }

    private resetMaskedRegions() {
        const message = new MaskedRegionsMessage();
        message.frameId = null;
        message.rects = [];
        this.handler(message);
    }
}