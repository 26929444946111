export const VIDEO_MASKING_SELECTOR = [
    '[autocomplete="cc-number"]',
    '[autocomplete="cc-exp"]',
    '[autocomplete="cc-exp-month"]',
    '[autocomplete="cc-exp-year"]',
    '[autocomplete="cc-csc"]',
    '[autocomplete="cc-scs"]',
    'iframe[title="paypal_card_form"]',
    'iframe[src*="adyen.com"]',
    'iframe[src*="www.computop-paygate.com"]',
    'iframe[src*="paymentcomponent.booking.com"]',
    'iframe[src*="checkout.mypos.eu"]',
    'form[action*="apx-security.amazon.com"]',
    'form[action*="oppwa.com"]',
    '[name="addCreditCardNumber"]',
    '[name="credit-card-number"]',
    '[name="ccnumber"]',
    '[name="ccexpmonth"]',
    '[name="ccexpyear"]',
    '[name="ccexp"]',
    '[name="cvv"]',
    '[name="cvv2"]',
    '[name^="credit_card"]',
    '[name="creditCard"]',
    '[name="maskedInput"]',
    '[name="payment[cc_number]"]',
    '[name="payment[cc_exp_month]"]',
    '[name="payment[cc_exp_year]"]',
    '[name="payment[cc_cid]"]',
    '#creditCardNumber',
    '#cc-number',
    '#cc-exp-month',
    '#cc-exp-year',
    '#credit-card-number',
    '#credit-card-expiration',
    '#credit-card-cvv',
    '#expiry-date',
    '#credit-card-security',
    '#card_number',
    '#card_cvn',
    '#cardentry',
    '#cc-panel--paymentstep',
    '#cardNum',
    '#card-number',
    '#expirationDate',
    '#expiration-date',
    '#expiration-month',
    '#expiration-year',
    '#card-cvv',
    '#cvv',
    '#credit_card_month',
    '#credit_card_year',
    '#addCardForm',
    '#securityCode',
    '#security-code',
    '#addNewPaymentForm',
    '#PaymentMethodForm',
    '#checkout-payment-credit-card-number-text',
    '#checkout-payment-credit-card-cvv-text',
    '#checkout-payment-credit-card-expire-month-dropdown',
    '#checkout-payment-credit-card-expire-month-dropdown div',
    '#checkout-payment-credit-card-expire-year-dropdown',
    '#checkout-payment-credit-card-expire-year-dropdown div',
    '.credit-card-wrapper',
    '.__PrivateStripeElement',
    '.ccnum',
    '.CreditCardNumber',
    '.card-num',
    '.cardno',
    '.card-number',
    '.cardNumber',
    '.expmonth',
    '.expyear',
    '.expiry',
    '.expDate',
    '.expiratioDate',
    '.expiry-date',
    '.expiryDate',
    '.CVC',
    '.ccField',
    '.securityCode',
    '.seccode',
    '.sec-code',
    '.CC',
    '.CVV',
    '.cvc-number',
    '.card-security',
    '.verificationCode',
    '.card-form',
    '.card_form',
    '.checkout_form',
    '.checkout-form',
    '.paymentForm',
    '.payment-form',
    '.pay-form',
    '.get-info-card-container',
    '.payment-container-body',
    '.row-for-credit-card-details'
].join(',');