export class FrameTransformer implements Transformer<any, any> {
    private stopped: boolean;
    public maskedRegions: DOMRect[];

    constructor(private tabId: number) {
        this.stopped = false;
        this.maskedRegions = [];
    }

    async transform(videoFrame, controller) {
        if (this.stopped) {
            videoFrame.close();
            controller.terminate();
            return;
        }

        if (this.maskedRegions.length < 1) {
            controller.enqueue(videoFrame);
            return;
        }

        const bitmap = await window.createImageBitmap(videoFrame);

        const timestamp = videoFrame.timestamp;
        const duration = videoFrame.duration;
        videoFrame.close();
        const newFrame = await this.blurRegions(bitmap, timestamp, duration);
        controller.enqueue(newFrame);
    }

    stop() {
        this.stopped = true;
    }

    private async getTab(): Promise<chrome.tabs.Tab> {
        return new Promise((resolve, reject) => {
            chrome.tabs.get(this.tabId, tab => {
                if (chrome.runtime.lastError) {
                    return reject(chrome.runtime.lastError);
                }

                return resolve(tab);
            });
        });
    }

    private async blurRegions(bitmap: ImageBitmap, timestamp: number, duration: number) {
        const canvas = new global.OffscreenCanvas(bitmap.width, bitmap.height);
        const ctx = canvas.getContext('2d');

        ctx.drawImage(bitmap, 0, 0);

        let tab: chrome.tabs.Tab = null;
        try {
            tab = await this.getTab();
        }
        catch (e) {
            if (chrome.tabs) {
                console.error(e);
            }
        }

        const offsetX = (tab && tab.width < bitmap.width) ? Math.floor((bitmap.width - tab.width) / 2) : 0;
        const offsetY = (tab && tab.height < bitmap.height) ? Math.floor((bitmap.height - tab.height) / 2) : 0;

        this.maskedRegions.forEach(region => {
            const x = offsetX + region.x;
            const y = offsetY + region.y;
            const width = region.width;
            const height = region.height;

            // ctx.fillRect(x, y, width, height);

            ctx.filter = 'blur(25px)';
            ctx.drawImage(canvas, x, y, width, height);
            ctx.filter = 'none';
            ctx.fillStyle = 'rgba(255, 255, 255, 1.0)';
            ctx.fillRect(x, y, width, height);
        });

        const width = bitmap.width;
        const height = bitmap.height;

        const imageData = ctx.getImageData(0, 0, width, height);
        const buffer = this.convertRgbaToYuv(imageData.data, width, height);

        bitmap.close();

        return new global.VideoFrame(buffer, {
            timestamp: timestamp,
            duration: duration,
            codedWidth: width,
            codedHeight: height,
            format: 'I420'
        });
    }

    private convertRgbaToYuv(rgba: Uint8ClampedArray, width: number, height: number): Uint8Array {
        const size = width * height;
        const vOffset = 1.25 * size;
        const buffer = new Uint8Array(1.5 * size);

        let row = -1;
        let uvPos = 0;
        for (let i = 0; i < size; i++) {
        if (i % width === 0) {
                row++;
            }

            const rgbVectorIndex = 4 * i;
            const r = rgba[rgbVectorIndex];
            const g = rgba[rgbVectorIndex + 1];
            const b = rgba[rgbVectorIndex + 2];

            const y = 0.257 * r + 0.504 * g + 0.098 * b + 16;
            buffer[i] = y;

            if (i % 2 === 0 && row % 2 === 0) {
                const u = -0.148 * r - 0.291 * g + 0.439 * b + 128;
                const v = 0.439 * r - 0.368 * g - 0.071 * b + 128;
                buffer[size + uvPos] = u;
                buffer[vOffset + uvPos] = v;
                uvPos++;
            }
        }

        return buffer;
    }
}