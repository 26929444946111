import { DOMElement } from "../models/dom-element.model";
import { ClickMessage } from "../models/messages/click-message.model";
import { isSafari } from "../services/util/browser-util";
import { EventHandler, getElementClass } from "./common";

//const windowObject = window as unknown as EventsWindow;

export const initClickEvents = (handler:EventHandler, captureScreenshot:(x:number, y:number, rect:DOMRect, t:string)=>any = undefined) => {
    const clickHandler = async (evt:any) => {
        const eventTime = new Date();
        // HACK: substract 5 milliseconds to make sure the click is stored before console logs
        eventTime.setTime(eventTime.getTime() - 5);
        await sendClickMessage(handler, evt, eventTime, captureScreenshot);
    };

    document.addEventListener('click', clickHandler, true);
    document.addEventListener('auxclick', clickHandler, true);
};

const sendClickMessage = async (handler:EventHandler, event: MouseEvent, time: Date, captureScreenshot: (x:number, y:number, rect:DOMRect, t:string)=>any) => {
    const linkElement = event.target as HTMLLinkElement;
    const elementIsClickable = (linkElement && linkElement.href?.trim() !== '') || isClickableElement(event.target as HTMLElement);
    await processClickEvent(handler, event, time, elementIsClickable, captureScreenshot);
};

const isClickableElement = (element: HTMLElement) => {
    const inputElement = element as HTMLInputElement;
    switch(element.tagName) {
        case 'BUTTON':
        case 'OPTION':
            return true;
        case 'INPUT':
            return inputElement.type?.toUpperCase() === 'SUBMIT'
                || inputElement.type?.toUpperCase() === 'BUTTON'
                || inputElement.type?.toUpperCase() === 'RESET';
        default:
            return false;
    }
};

const processClickEvent = async (handler:EventHandler, event: MouseEvent, time: Date, checkActiveElement: boolean, captureScreenshot: (x:number, y:number, rect:DOMRect, t:string)=>any) => {
    const element = event.target as HTMLElement;
    let shouldCaptureScreenshot = true;
    const linkElement = event.target as HTMLLinkElement;
    const href = linkElement && linkElement.href;
    const rect: DOMRect = element.getBoundingClientRect();
    let dataUrl;

    if(checkActiveElement && shouldCaptureScreenshot) {
        // we don't need to make these checks if we know that the element is clickable
        if (!isSafari && document.activeElement.nodeName.toUpperCase() === 'BODY') {
            // we don't want to create screenshots for body clicks
            shouldCaptureScreenshot = false;
        }

        if (rect.x === 0 && rect.y === 0 && rect.width === window.innerWidth && rect.height === window.innerHeight) {
            // we don't want capture click on items matching the full screen
            shouldCaptureScreenshot = false;
        }
    }

    const timeString = time.toISOString();
    if (shouldCaptureScreenshot && captureScreenshot) {
        dataUrl = await captureScreenshot(event.clientX, event.clientY, rect, timeString);
    }

    let tagName = element.nodeName;

    if (tagName.toUpperCase() === 'INPUT') {
        if (element.attributes) {
            const inputType = element.attributes.getNamedItem("type");
            if (inputType) {
                tagName = `INPUT>${inputType.value}`;
            }
        }
    }

    const domElement: DOMElement = {
        text: element.innerText,
        visibleText: element.textContent,
        tag: tagName,
        href,
        rect,
        devicePixelRatio: window.devicePixelRatio,
        class: getElementClass(element),
        id: element.id
    };

    const eventMsg = new ClickMessage();
    eventMsg.timeString = timeString;
    eventMsg.clientX = event.clientX;
    eventMsg.clientY = event.clientY;
    eventMsg.userAgent = navigator.userAgent;
    //eventMsg.document = window.document;
    eventMsg.element = domElement;
    eventMsg.shouldCaptureScreenshot = shouldCaptureScreenshot;
    // get the frame id from the window object which should be already set if this is an iframe
    //eventMsg.frameId = windowObject._fiddlerJam_frameId;
    eventMsg.startedDateTime = new Date(eventMsg.timeString);
    eventMsg.screenshotDataUrl = dataUrl;

    handler(eventMsg);
};