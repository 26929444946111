async function deriveKey(passphrase: string, saltBuffer: Uint8Array, iterations: number, keyLength: 128|256 = 256): Promise<CryptoKey> {
    const encoder = new TextEncoder();
    const passphraseKey = encoder.encode(passphrase);

    const key = await crypto.subtle.importKey(
        'raw',
        passphraseKey,
        {
            name: 'PBKDF2'
        },
        false,
        [ 'deriveKey' ]
    );

    const derivedKey = await crypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt: saltBuffer,
            iterations,
            hash: 'SHA-256'
        },
        key,
        {
            name: 'AES-CTR',
            length: keyLength
        },
        true,
        [ 'encrypt' ]
    );

    return derivedKey;
}

function numberToArrayBuffer(value) {
    const view = new DataView(new ArrayBuffer(8));
    for (let index = 7; index >= 0; --index) {
        view.setUint8(index, value % 256);
        // tslint:disable-next-line:no-bitwise
        value = value >> 8;
    }
    return view.buffer;
}

export function generateSalt(): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(8));
}

export async function encrypt(plaintext: Uint8Array | Blob, passphrase: string, saltBuffer: Uint8Array, iterations: number): Promise<ArrayBuffer> {
    if (plaintext instanceof Blob) {
        const buffer = await plaintext.arrayBuffer();
        plaintext = new Uint8Array(buffer);
    }

    const key = await deriveKey(passphrase, saltBuffer, iterations);

    const counterIndex = new Uint8Array(numberToArrayBuffer(0));
    const counter = new Uint8Array(saltBuffer.length + counterIndex.length);
    counter.set(saltBuffer);
    counter.set(counterIndex, saltBuffer.length);

    const algorithm = {
        name: 'AES-CTR',
        counter,
        length: 64
    };

    const ciphertext = await crypto.subtle.encrypt(algorithm, key, plaintext);

    return ciphertext;
}

export async function getKey(passphrase: string, saltBuffer: Uint8Array, iterations: number, keyLength: 128|256 = 256): Promise<ArrayBuffer> {
    const key = await deriveKey(passphrase, saltBuffer, iterations, keyLength);
    const rawKey = await crypto.subtle.exportKey('raw', key);
    return rawKey;
}

export function generateRandomBytes(size: number = 32): string {
    const bytes = crypto.getRandomValues(new Uint8Array(size));
    return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
}
